/*global chrome*/
import React, { useEffect, useState, useRef } from "react";
import Overview from "./components/Accordions/Overview";
import Dimension from "./components/Accordions/Dimension";
import RanksPrices from "./components/Accordions/RanksPrices";
import Lookup from "./components/Accordions/Lookup";
import Variations from "./components/Accordions/Variations";
import Alerts from "./components/Accordions/Alerts";
import ProfitCalculator from "./components/Accordions/ProfitCalculator";
import ROI from "./components/Accordions/ROI";
import Offers from "./components/Accordions/Offers";
import GoogleSheets from "./components/Accordions/GoogleSheets";
import BuyBoxAnalysis from "./components/Accordions/BuyBoxAnalysis";

import NavigationBar from "./components/NavigationBar";
import axios from "axios";

import { IoArrowBackCircle } from "react-icons/io5";
import { RiMoneyDollarBoxFill } from "react-icons/ri";
import { MdChromeReaderMode } from "react-icons/md";
import { IoIosPricetags } from "react-icons/io";
import { GoAlertFill } from "react-icons/go";
import { FaChartLine } from "react-icons/fa";
import { TbResize } from "react-icons/tb";
import { BiSolidOffer } from "react-icons/bi";
import { TbReportMoney } from "react-icons/tb";
import { FaSheetPlastic } from "react-icons/fa6";

import {
  fetchCreditsData,
  fetchUserDetails,
} from "./redux/actions/userActions";
import {
  fetchProductDetails,
  fetchSalesData,
  fetchSettings,
} from "./redux/actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import Chart from "./components/Accordions/Chart";
import TooltipSimple from "./components/SettingComponents/TooltipSimple";

export default function AccordionTransition({ asin, settingsData }) {
  const [data, setData] = useState(null);
  const [salesData, setSalesData] = useState(null);

  const [expanded, setExpanded] = React.useState(false);
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const userData = useSelector((state) => state.userData);
  const [cookieValue, setCookieValue] = useState("");
  const [allCookieNames, setAllCookieNames] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [settingsData, setSettingsData] = useState(null);
  const [spreadsheetId, setSpreadsheetId] = useState(null);
  const [showSection, setShowSection] = useState({
    overview: true,
    ranksPrices: true,
    alerts: true,
    profitCalculator: true,
    chart: true,
    dimensions: true,
    offers: true,
    lookup: true,
    roi: true,
    buyBox: true,
    variations: true,
    googleSheets: true,
  });

  useEffect(() => {
    const allCookies = document.cookie
      .split(";")
      .map((cookie) => cookie.trim().split("=")[0]);
    console.log("allCookies", allCookies);
    setAllCookieNames(allCookies);
  }, []);

  useEffect(() => {
    const panels = settingsData?.panels;
    const alerts = panels?.alerts;
    const charts = panels?.charts;
    const profit_calculator = panels?.profit_calculator;
    const ranks_and_prices = panels?.ranks_and_prices;
    const variations = panels?.variations;

    setShowSection({
      ...showSection,
      ranksPrices: ranks_and_prices,
      alerts: alerts,
      profitCalculator: profit_calculator,
      chart: charts,
      variations: variations,
    });
  }, [settingsData]);

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return "";
  };

  const handleGetCookie = (name) => {
    const value = getCookie(name);
    setCookieValue(value);
  };
  const overviewRef = useRef(null);
  const ranksPricesRef = useRef(null);
  const alertsRef = useRef(null);
  const profitCalculatorRef = useRef(null);
  const chartRef = useRef(null);
  const dimensionsRef = useRef(null);
  const offersRef = useRef(null);
  const lookupRef = useRef(null);
  const roiRef = useRef(null);
  const buyBoxRef = useRef(null);
  const variationsRef = useRef(null);
  const googleSheetsRef = useRef(null);

  const handleIconClick = (ref) => {
    if (ref && ref.current) {
      const element = ref.current;
      const offsetTop = element.getBoundingClientRect().top;
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      const targetScrollPosition = scrollPosition + offsetTop - 100;
      window.scrollTo({ top: targetScrollPosition, behavior: "smooth" });
    }
  };

  // console.log('userData in Products Page', userData);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchCreditsData();

      dispatch({
        type: "SET_CREDITS",
        payload: response,
      });
      if (response) {
      } else {
        return;
      }
      // getAsin();
      if (asin) {
        const apiData = await fetchProductDetails({ asin });
        setData(apiData);

        const globalData = {
          // total_fees: Number(data?.profitCalculator?.total_fees?.breakdown?.FBAFees),
          total_fees: Number(apiData?.profitCalculator?.total_fees?.total),
          prices: {
            cost_price: Number(apiData?.profitCalculator?.prices?.cost_price),
            sale_price: Number(apiData?.profitCalculator?.prices?.sale_price),
          },
          profit: apiData?.profitCalculator?.profit,
          roi: apiData?.profitCalculator?.roi,
          profit_percentage: apiData?.profitCalculator?.profit_percentage,
          roi_percentage: apiData?.profitCalculator?.roi_percentage,
          selectedFulfillment: "FBA",
          fullfillmentFees: {
            FBA: Number(
              apiData?.profitCalculator?.total_fees?.fba_breakdown?.FBAFees
            ),
            FBM: Number(
              apiData?.profitCalculator?.total_fees?.fbm_breakdown?.FBMFees
            ),
          },
        };

        console.log("globalData", globalData);
        dispatch({
          type: "UPDATE_GLOBAL_STATES",
          payload: globalData,
        });

        setLoading(false);

        console.log(
          "params",
          asin,
          apiData?.overview?.BSR?.BSR,
          apiData?.basicInfo?.category
        );
        const salesData = await fetchSalesData(
          asin,
          apiData?.overview?.BSR?.BSR,
          apiData?.basicInfo?.category
        );
        console.log("salesData", salesData);
        setSalesData(salesData);

        const { user } = await fetchUserDetails();
        setSpreadsheetId(user?.spreadsheetId);

        // const settingsData = await fetchSettings();
        // setSettingsData(settingsData);

        dispatch({
          type: "SET_SETTINGS",
          payload: settingsData,
        });

        console.log("settingsData", settingsData);
      }
    };

    fetchData();
  }, [spreadsheetId]);

  console.log("data in ProductPage", data);
  return (
    <div className="w-full " style={{}}>
      <div
        className=""
        // style={{ height: '82vh', overflowY: 'scroll' }}
      >
        <div
          className="flex flex-col w-full border-primary-light bg-primary p-3"
          style={{
            position: "sticky",
            top: "2px",
            border: "0.5px solid #76F99B",
            borderRadius: "24px",
            zIndex: 100,
          }}
        >
          <div className="flex">
            <div
              class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark cursor-pointer"
              onClick={() => handleIconClick(overviewRef)}
              style={
                {
                  // background: "#052308",
                }
              }
            >
              <MdChromeReaderMode />
              <TooltipSimple width={"auto"} text={`Overview`} />
            </div>
            <div
              class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark cursor-pointer"
              onClick={() => handleIconClick(ranksPricesRef)}
              style={
                {
                  // background: "#052308",
                }
              }
            >
              <IoIosPricetags />
              <TooltipSimple width={"auto"} text={`Rank & Prices`} />
            </div>
            <div
              class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark cursor-pointer"
              onClick={() => handleIconClick(alertsRef)}
              style={
                {
                  // background: "#052308",
                }
              }
            >
              <TooltipSimple width={"auto"} text={`Alerts`} />
              <GoAlertFill />
            </div>
            <div
              class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark cursor-pointer"
              onClick={() => handleIconClick(profitCalculatorRef)}
              style={
                {
                  // background: "#052308",
                }
              }
            >
              <RiMoneyDollarBoxFill className="" />
              <TooltipSimple width={"auto"} text={`Profit Calculator`} />
            </div>
            <div
              class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark cursor-pointer"
              onClick={() => handleIconClick(chartRef)}
              style={
                {
                  // background: "#052308",
                }
              }
            >
              <FaChartLine />
              <TooltipSimple width={"auto"} text={`Charts`} />
            </div>
            <div
              class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark cursor-pointer"
              onClick={() => handleIconClick(dimensionsRef)}
              style={
                {
                  // background: "#052308",
                }
              }
            >
              <TbResize />
              <TooltipSimple width={"auto"} text={`Dimensions`} />
            </div>
            <div
              class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark cursor-pointer"
              onClick={() => handleIconClick(offersRef)}
              style={
                {
                  // background: "#052308",
                }
              }
            >
              <BiSolidOffer />
              <TooltipSimple width={"auto"} text={`Offers`} />
            </div>
            {/* <div class="w-8 h-8 rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark cursor-pointer"
              onClick={() => handleIconClick(lookupRef)}
              style={{
                // background: "#052308",
              }}>
              <TbReportMoney />
              </div> */}
            <div
              class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark cursor-pointer"
              onClick={() => handleIconClick(roiRef)}
              style={
                {
                  // background: "#052308",
                }
              }
            >
              <TbReportMoney />
              <TooltipSimple width={"auto"} text={`ROI`} />
            </div>
            {/* <div class="w-8 h-8 rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark cursor-pointer"
              onClick={() => handleIconClick(variationsRef)}
              style={{
                // background: "#052308",
              }}>
              <TbReportMoney />
            </div> */}
            <div
              class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark cursor-pointer"
              onClick={() => handleIconClick(googleSheetsRef)}
              style={
                {
                  // background: "#052308",
                }
              }
            >
              <FaSheetPlastic />
              <TooltipSimple width={"auto"} text={`Google Sheets`} />
            </div>
          </div>
        </div>

        {showSection.overview && (
          <div ref={overviewRef}>
            <Overview
              asin={asin}
              eanList={data?.eanList}
              data={{ ...data?.overview, image: data?.image }}
              rating={data?.rating}
              basicInfo={data?.basicInfo}
              amazonFees={Number(data?.profitCalculator?.total_fees?.total)}
              salesData={salesData}
              loading={loading}
              settingsData={settingsData}
              dimensions={data?.dimensions}
              amazonLink={data?.sellerCentral?.amazonLink}
              description={data?.description}
              referralFeePercent={Number(
                data?.profitCalculator?.total_fees?.fba_breakdown
                  ?.referralFeePercent
              )}
            />
          </div>
        )}

        <div className="text-white">
          {showSection.ranksPrices && (
            <div ref={ranksPricesRef}>
              <RanksPrices
                data={data?.ranksandprices}
                loading={loading}
                salesData={salesData}
                settingsData={settingsData}
              />
            </div>
          )}

          {showSection.alerts && (
            <div ref={alertsRef}>
              <Alerts data={data?.alerts} />
            </div>
          )}

          {showSection.profitCalculator && (
            <div ref={profitCalculatorRef}>
              <ProfitCalculator
                data={data?.profitCalculator}
                loading={loading}
              />
            </div>
          )}

          {showSection.chart && (
            <div ref={chartRef}>
              <Chart
                data={{ asin: asin }}
                ranksandprices={data?.ranksandprices}
                loading={loading}
              />
            </div>
          )}

          {showSection.dimensions && (
            <div ref={dimensionsRef}>
              <Dimension data={data?.dimensions} loading={loading} />
            </div>
          )}

          {showSection.offers && (
            <div ref={offersRef}>
              <Offers data={data?.offers} loading={loading} />
            </div>
          )}

          {showSection.lookup && <Lookup loading={loading} asin={asin} />}

          {showSection.roi && (
            <div ref={roiRef}>
              <ROI
                loading={loading}
                salesData={salesData}
                data={data?.profitCalculator}
              />
            </div>
          )}

          {showSection.buyBox && (
            <div ref={buyBoxRef}>
              <BuyBoxAnalysis
                loading={loading}
                data={data?.buyboxAnalysis}
                settingsData={settingsData}
              />
            </div>
          )}

          {showSection.variations && (
            <div ref={variationsRef}>
              <Variations loading={loading} data={data?.variations} />
            </div>
          )}

          {showSection.googleSheets && (
            <div ref={googleSheetsRef}>
              <GoogleSheets
                data={data}
                salesData={salesData}
                spreadsheetId={spreadsheetId}
                setSpreadsheetId={setSpreadsheetId}
              />
            </div>
          )}

          <div
            className="flex justify-center"
            style={{
              height: "60px",
            }}
          ></div>
        </div>
      </div>
      <div
        className="pr-5 w-full"
        style={{
          position: "fixed",
          bottom: "5px",
        }}
      >
        {/* <NavigationBar /> */}
      </div>
    </div>
  );
}
