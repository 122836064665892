import React from 'react'
import '../styles.css';

const GoogleSignInButton = ({ onClickHandler, loginLoading }) => {
    return (
        (loginLoading === 'google') ?
            <button className="google-signin-button" disabled>
                <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google logo" />
                Loading...
            </button>
            :
            <button className="google-signin-button" onClick={onClickHandler}>
                <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google logo" />
                Sign in with Google
            </button>

    )
}

export default GoogleSignInButton
