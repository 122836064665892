import React from 'react'
import { FaCheck, FaCopy } from 'react-icons/fa';
import Rating from '@mui/material/Rating';
import SkeletonLine from '../SkeletonLine';
import TooltipSimple from './TooltipSimple';

const SearchProductCard = ({ data, loading, setAsin, settingsData }) => {

    return (
        <div
            className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
            style={{
                border: '0.5px solid #76F99B',
                borderRadius: '24px',
            }}
        >
            <div className="flex items-center">
                <div className="inline-block top-20 left-20 gap-0 w-[40%]">

                    {!data?.image || loading ? (
                        <div
                            className="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 dark:bg-secondary w-[40%]"
                            style={{
                                border: '0.5px solid #76F99B',
                                background: 'rgba(118, 249, 155, 0.35)',
                                borderRadius: '30px',
                                height: '130px',
                            }}
                        >
                            <svg
                                className="w-10 h-10 text-gray-200 dark:text-gray-600"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 18"
                            >
                                <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                            </svg>
                        </div>
                    ) : (
                        <>
                            <div className='rounded-t-xl p-1 bg-secondary'
                                style={{
                                    border: '0.5px solid #76F99B',
                                    background: 'rgba(118, 249, 155, 0.35)',
                                    borderRadius: '12px',
                                    minHeight: '120px',
                                }}>
                                <div className="border-primary-light">
                                    <img
                                        src={data?.image}
                                        alt="Product"
                                        className="w-auto m-auto rounded-t-lg"
                                        style={{
                                            borderRadius: '12px',
                                            minHeight: '100px',
                                            maxHeight: '300px',
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {/* <div className="box mt-2 group border rounded-lg p-3 bg-secondary relative flex justify-between"
                        style={{
                            fontSize: '14px',
                            width: 'fit-content',
                            marginLeft: 'auto',
                        }}>
                        <p className="text-primary-light">${data?.sale_price}</p>
                        <TooltipSimple text={`Sale Price: ${data?.sale_price}`} />
                    </div> */}
                </div>


                <div className="text-primary-light text-left w-[60%] px-4">
                    {!loading ? (
                        <>
                            <div
                                className="text-white font-bold"
                                style={{
                                    fontSize: '12px',
                                }}
                            >
                                {data?.brand || ''}
                            </div>
                            <div
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                                className='group cursor-pointer relative'
                                onClick={() => setAsin(data?.asin)}
                            >
                                {data?.productName
                                    ? data?.productName?.length > 40
                                        ? data?.productName.substring(0, 40) + '...'
                                        : data?.productName
                                    : '--'}
                                <TooltipSimple text={`Product Name: ${data?.productName}`} width='extra' />
                            </div>
                            <div className="flex items-end mt-1">
                                <Rating
                                    name="read-only"
                                    value={Number(data?.rating?.rating || 0)}
                                    precision={0.1}
                                    readOnly
                                    style={{
                                        fontSize: '13px',
                                        color: '#76F99B',
                                    }}
                                />
                                <span
                                    className="text-white ml-1"
                                    style={{
                                        fontSize: '10px',
                                    }}
                                >
                                    {data?.rating?.rating || 0}
                                </span>
                            </div>
                            <div
                                className="mt-2"
                                style={{
                                    fontSize: '0.7rem',
                                }}
                            >
                                <div className="text-white">
                                    ASIN: {data?.asin}
                                    <span className="cursor-pointer text-primary-light">
                                        <FaCopy
                                            className="ml-2"
                                            style={{
                                                fontSize: '0.7rem',
                                                display: 'inline',
                                            }}
                                            onClick={() => {
                                                navigator.clipboard.writeText(data?.asin);
                                            }}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="flex mt-2 mb-5 justify-between" style={{ fontSize: '14px' }}>
                                <div className={`rounded-lg w-[48%] text-center relative mr-2 ${(Number(settingsData?.buyingCriteria?.minbsr) > Number(data?.BSR?.percentage?.substring(0, data?.BSR?.percentage?.length - 1)) || (data?.BSR?.BSR === -1))
                                    ? 'text-white bg-[#E74B4B]' : 'text-primary-light bg-secondary'}`}>
                                    <div className='p-1 bg-secondary' style={{
                                        borderTopRightRadius: '7px',
                                        borderTopLeftRadius: '7px',
                                        height: '40px'

                                    }}>
                                        <div className="text-white bg-secondary px-4 pt-3" style={{
                                        }}>BSR</div>
                                    </div>
                                    <div className='relative group text-xs w-full px-4 py-2'
                                    >
                                        {loading ? <SkeletonLine /> : (
                                            <>
                                                <div className="flex flex-wrap">
                                                    {(data?.BSR?.BSR !== -1) ?
                                                        <>
                                                            <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                                                                {data?.BSR?.BSR ? data?.BSR?.BSR : '--'}
                                                            </span>
                                                            <span className="whitespace-nowrap">
                                                                {data?.BSR?.percentage ? '(' + (data?.BSR?.percentage) + ')' : '(--)'}
                                                            </span>
                                                        </>
                                                        :
                                                        <div className="overflow-hidden text-center whitespace-nowrap">--</div>
                                                    }
                                                </div>
                                                {/* TOOLTIP SIMPLE */}
                                                <TooltipSimple
                                                    text={
                                                        (Number(settingsData?.buyingCriteria?.minbsr) > Number(data?.BSR?.BSR)) ? `BSR(${data?.BSR?.BSR}) is less than desired BSR(${settingsData?.buyingCriteria?.minbsr})` : `BSR(${data?.BSR?.BSR}) is above desired BSR level (${settingsData?.buyingCriteria?.minbsr})`
                                                    }
                                                />
                                            </>
                                        )}

                                    </div>
                                </div>
                                <div className={`rounded-lg min-w-[50%] text-center relative text-primary-light bg-secondary`}>
                                    <div className='p-1 bg-secondary' style={{
                                        borderTopRightRadius: '7px',
                                        borderTopLeftRadius: '7px',
                                        height: '40px'
                                    }}>
                                        <div className="text-white bg-secondary pl-1 pr-2 pt-3" style={{
                                            textWrap: 'nowrap',
                                        }}>Max. Cost</div>
                                    </div>
                                    <div className='relative group text-xs w-full px-4 py-2'
                                    >
                                        {loading ? <SkeletonLine /> : (
                                            <>
                                                <span className='block overflow-hidden text-ellipsis whitespace-nowrap'>
                                                    ${data?.max_cost ? data?.max_cost : '-'}
                                                </span>
                                                {/* TOOLTIP SIMPLE */}
                                                <TooltipSimple
                                                    text={`Max Cost: ${data?.max_cost ? data?.max_cost : '--'}`}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>

                                {/* <div className="box mt-2 border rounded-lg p-3 bg-secondary relative" style={{ width: '32%' }}>
                                    <h3 className="text-white" style={{
                                        fontSize: '13px',
                                        textWrap: 'nowrap',
                                    }}>Offers  - {data?.totalOffersCount}</h3>
                                    <p className='text-primary-light relative group'>
                                    </p>
                                </div> */}
                            </div>

                        </>
                    ) : (
                        <div
                            role="status"
                            className="animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center flex items-center"
                        >
                            <div className="w-full">
                                <div className="h-2.5 bg-gray-400 rounded-full dark:bg-secondary max-w-[360px] mb-4"></div>
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary max-w-[200px] mb-2.5"></div>
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary mb-2.5"></div>
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary max-w-[100px] mb-2.5"></div>
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary max-w-[100px] mb-2.5"></div>
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary max-w-[100px]"></div>
                            </div>
                            <span className="sr-only">Loading...</span>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex items-center">
                <div className='inline-block left-20 gap-0 w-[40%]'>
                    <div className="box mt-2 group border rounded-lg p-3 bg-secondary relative flex justify-between"
                        style={{
                            fontSize: '14px',
                            width: 'fit-content',
                            marginLeft: 'auto',
                        }}>
                        {loading ? <SkeletonLine /> : (
                            <p className="text-white">${data?.sale_price}</p>
                        )}
                        <TooltipSimple text={`Sale Price: ${data?.sale_price}`} />
                    </div>
                </div>
                <div className='text-primary-light text-left w-[60%] px-4'>
                    <div className="mt-2 rounded-lg p-3 bg-secondary relative">
                        {loading ? <SkeletonLine /> : (
                            <h3 className="text-white" style={{
                                fontSize: '13px',
                                textWrap: 'nowrap',
                            }}>
                                Offers  - {data?.totalOffersCount}
                            </h3>
                        )}
                        <p className='text-primary-light relative group'>
                            {/* {loading ? <SkeletonLine /> : (
                                            <>
                                                <span className='block overflow-hidden text-ellipsis whitespace-nowrap'>
                                                    {data?.max_cost ? data?.max_cost : '-'}
                                                </span>
                                                <TooltipSimple
                                                    text={`Max Cost: ${data?.max_cost ? data?.max_cost : '--'}`}
                                                />
                                            </>
                                        )} */}
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <div>
                    <div
                        className="flex cursor-pointer items-center mb-2 text-white"
                    // onClick={toggleAccordion}
                    >
                        <div
                            className="mr-1 text-primary-light"
                            style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            {/* Overview */}
                        </div>
                        <div>{/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchProductCard
