import React, {useState, useEffect} from 'react'

const Tags = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [selectedOption, setSelectedOption] = useState('Current');

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className='flex flex-col w-full border-primary-light bg-primary p-4 mt-4' style={{
            border: '0.5px solid #76F99B',
            borderRadius: '24px',
        }}>
            <div className='flex cursor-pointer items-center mb-2'
                onClick={toggleAccordion}>
                <div className='mr-1 text-primary-light font-bold' style={{
                    fontSize: '16px',
                }}>
                    Tags
                </div>
                <div>
                    {/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}
                </div>
            </div>
            {isOpen && (
                <>
                    
                </>)}
        </div>
    )
}

export default Tags
