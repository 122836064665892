// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// const firebaseConfig = {
//   apiKey: "AIzaSyD71X076SBPCoYY9rN6tpqms6IZ7pB0yrc",
//   authDomain: "scan-profit.firebaseapp.com",
//   projectId: "scan-profit",
//   storageBucket: "scan-profit.appspot.com",
//   messagingSenderId: "577567306703",
//   appId: "1:577567306703:web:539c68bad68d833c9158ba",
//   measurementId: "G-7Y4K4ZEEM2",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDx9ypVcVBugfPejn39VbSX5OrfhXh1P_Q",
  authDomain: "scan-profit-30068.firebaseapp.com",
  projectId: "scan-profit-30068",
  storageBucket: "scan-profit-30068.appspot.com",
  messagingSenderId: "554183288480",
  appId: "1:554183288480:web:362b603887646c03550bc5",
  measurementId: "G-EW8E8SRQQ4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);