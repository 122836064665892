import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";

const Select = ({ inputValue, handleDropdownChange, dropdownOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(inputValue);

  useEffect(() => {
    setSelectedOption(inputValue);
  }, [inputValue]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    handleDropdownChange(option);
    setIsOpen(false);
  };

  return (
    <div className="relative w-full">
      <div
        className="flex justify-between items-center text-white border border-primary-light rounded-xl bg-primary py-2 px-3 cursor-pointer"
        style={{ borderRadius: "12px" }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selectedOption || "Select an option"}</span>
        <span className="ml-2">
          <FaAngleDown />
        </span>
      </div>
      {isOpen && (
        <div
          className="absolute w-full bg-[#153E27] border text-white border-primary-light rounded-xl mt-1 z-10 capitalize"
          style={{
            maxHeight: "150px",
            overflowY: "auto",
            borderRadius: "12px",
            zIndex: 10,
          }}
        >
          {dropdownOptions.map((option, index) => (
            <div
              key={index}
              className="py-1 px-3 text-left cursor-pointer bg-[#153E27] hover:bg-secondary"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
