import React, { useState, useEffect, useRef } from 'react';
import Tooltip from './Tooltip';
import SaveButtons from '../SaveButtons';

const BuyingCriteria = ({ data, handleUpdate }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [properties, setProperties] = useState([]);
    const [showSave, setShowSave] = useState(false);
    const inputRefs = useRef([]);

    const handleChange = (e, idx) => {
        const value = e.target.value.replace(/[^0-9.]/g, ''); // Remove any non-numeric characters
        const updatedProperties = [...properties];
        updatedProperties[idx].value = value;
        setProperties(updatedProperties);
        setShowSave(true);
    };

    const handleSave = () => {
        const updatedData = properties.reduce((acc, property) => {
            acc[property.property] = property.value || "0";
            return acc;
        }, {});

        // update the data object
        data = { ...data, ...updatedData };
        handleUpdate(data);
        setShowSave(false);
    };

    const handleCancel = () => {
        setProperties(createPropertiesFromData(data));
        setShowSave(false);
    };

    useEffect(() => {
        setProperties(createPropertiesFromData(data));
    }, [data]);

    const createPropertiesFromData = (data) => {
        return [
            {
                title: 'Minimum BSR(%)',
                value: data?.minbsr,
                property: 'minbsr',
                text: 'Minimum BSR is the minimum BSR you want to set for the product.'
            },
            {
                title: 'Maximum BSR(%)',
                value: data?.maxbsr ? data?.maxbsr : '0',
                property: 'maxbsr',
                text: 'Maximum BSR is the maximum BSR you want to set for the product.'
            },
            {
                title: 'Minimum Profit($)',
                value: data?.minProfit,
                property: 'minProfit',
                text: 'Minimum Profit is the minimum profit you want to set for the product.'
            },
            {
                title: 'Minimum ROI(%)',
                value: data?.minroi,
                property: 'minroi',
                text: 'Minimum ROI is the minimum ROI you want to set for the product.'
            }
        ];
    };

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };


    // console.log('properties', properties, data);
    const handleFocus = (idx) => {
        const input = inputRefs.current[idx];
        const valueLength = properties[idx].value.length;
        input.setSelectionRange(valueLength, valueLength);
    };

    return (
        <div className='flex flex-col w-full border-primary-light bg-primary p-4 mt-4' style={{
            border: '0.5px solid #76F99B',
            borderRadius: '24px',
        }}>
            <div className='flex cursor-pointer items-center mb-2'
                onClick={toggleAccordion}>
                <div className='mr-1 text-primary-light font-bold' style={{
                    fontSize: '16px',
                }}>
                    Buying Criteria
                </div>
                <div>
                    {/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}
                </div>
            </div>
            {isOpen && (
                <>
                    {properties.map((property, index) => (
                        <div key={index} className="flex text-white justify-between bg-secondary p-4 rounded-lg align-center mt-2"
                            style={{
                                fontSize: '13px',
                            }}>
                            <div className='w-3/4 flex items-center' style={{ textAlign: 'left' }}>
                                {property.title} <Tooltip text={property.text} />
                            </div>
                            <div className='w-1/4'>
                                <input
                                    type="text"
                                    value={`${property.value}${property.title.includes('%') ? '%' : property.title.includes('$') ? '$' : ''}`}
                                    onChange={(e) => handleChange(e, index)}
                                    onFocus={() => handleFocus(index)}
                                    ref={el => inputRefs.current[index] = el}
                                    placeholder="$20"
                                    className={`text-center text-white border border-primary-light rounded-xl ${property.title === 'Fullfillment' ? 'bg-secondary' : 'bg-primary-dark'}`}
                                    style={{
                                        borderRadius: '12px',
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                    {showSave && (
                        <SaveButtons handleSave={handleSave} handleCancel={handleCancel} />
                    )}
                </>
            )}
        </div>
    )
}

export default BuyingCriteria;
