import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

const options = {
  Live: "liveOffers",
  "All Offers": "allOffers",
};

const Offers = ({ data }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Live");
  const [visibleCount, setVisibleCount] = useState(10);
  const [hasMoreOffers, setHasMoreOffers] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const loadMoreOffers = () => {
    setVisibleCount(visibleCount + 10);
  };

  // Check if there are more offers to load
  const checkMoreOffers = () => {
    if (data?.[options[selectedOption]]?.length <= visibleCount) {
      setHasMoreOffers(false);
    } else {
      setHasMoreOffers(true);
    }
  };

  useEffect(() => {
    checkMoreOffers();
  }, [visibleCount, selectedOption, data]);

  return (
    <div
      className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
      style={{
        border: "0.5px solid #76F99B",
        borderRadius: "24px",
      }}
    >
      <div className="">
        <div
          className="flex cursor-pointer items-center mb-2"
          onClick={toggleAccordion}
        >
          <div
            className="mr-1 text-primary-light font-bold"
            style={{
              fontSize: "16px",
            }}
          >
            Offers
          </div>
          <div>{/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}</div>
        </div>
        {isOpen && (
          <>
            <div className="flex mb-2">
              {Object.keys(options).map((option, index) => (
                <div
                  key={index}
                  className={`cursor-pointer text-primary-light ${
                    selectedOption === option ? "text-white" : ""
                  } bg-secondary px-2 py-1 rounded-lg mr-1`}
                  onClick={() => {
                    setVisibleCount(10);
                    setSelectedOption(option);
                  }}
                  style={{ fontSize: "12px" }}
                >
                  {option}
                </div>
              ))}
            </div>
            <div className="relative">
              <table
                className="w-full mt-2"
                style={{ fontSize: "13px", borderCollapse: "collapse" }}
              >
                <thead className="sticky top-0 bg-primary">
                  <tr className="" style={{ fontWeight: "bold" }}>
                    <div className="flex">
                      <div className="p-1 flex-2"></div>
                      <div className="p-1 flex-1">Seller</div>
                      <div className="p-1 flex-1">Stock</div>
                      <div className="p-1 flex-1">Price</div>
                      <div className="p-1 flex-1">Profit</div>
                      <div className="p-1 flex-1">ROI</div>
                    </div>
                  </tr>
                </thead>
                <tbody>
                  {data?.[options[selectedOption]]
                    ?.slice(0, visibleCount)
                    ?.map((property, index) => (
                      <tr key={index}>
                        <td className="p-0">
                          <div
                            className="text-center text-white bg-secondary p-4 rounded-lg"
                            style={{
                              marginBottom: "4px",
                              marginTop: index === 0 ? "0" : "4px",
                              padding: "10px",
                            }}
                          >
                            <div className="flex items-center justify-around">
                              <div className="p-1 pl-0 pr-2 flex-2">
                                {index + 1}
                              </div>{" "}
                              {/* Serial Number */}
                              <div
                                className={`${
                                  property?.isAmazon
                                    ? "bg-[#533215]"
                                    : property?.isFBA
                                    ? "bg-[#653d19]"
                                    : "bg-[#634f19]"
                                } rounded-lg p-1 flex-1`}
                                style={{
                                  width: "fit-content",
                                  border: property?.isFBA
                                    ? "1px solid #d5a109"
                                    : "1px solid #e4b20f",
                                }}
                              >
                                {property?.isAmazon
                                  ? "AMZ"
                                  : property?.isFBA
                                  ? "FBA"
                                  : "FBM"}
                              </div>
                              <div className="p-1 flex-1">
                                {property?.stock || "-"}
                              </div>
                              <div className="p-1 flex-1">
                                ${property?.price}
                              </div>
                              <div className="p-1 flex-1">
                                {property?.profit}
                              </div>
                              <div className="p-1 flex-1">{property?.roi}</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              {hasMoreOffers && (
                <button
                  onClick={loadMoreOffers}
                  className="mt-4 px-4 py-2 bg-primary-light text-primary rounded-lg"
                  style={{ fontSize: "14px" }}
                >
                  Load More
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Offers;
