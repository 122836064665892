const initState = {
    creditsData: {
        credit_balance: 0,
        credit_total: 0,
    },
};

export default (state = initState, action) => {
    switch (action.type) {
        case "SET_CREDITS":
            return {
                ...state,
                creditsData: action.payload,
            };
        default:
            return state;
    }
};
