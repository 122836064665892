import {combineReducers} from "redux";

import auth from "./auth";
import userData from "./userReducers";
import GlobalStates from "./GlobalStates";

export default combineReducers({
  auth: auth,
  userData: userData,
  globalStates: GlobalStates,
});
