import React from 'react'

const AppleSignInButton = ({ onClickHandler }) => {
    return (
        <button className="apple-signin-button" onClick={onClickHandler}>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/814px-Apple_logo_black.svg.png" alt="Sign in with Apple logo" />
            Sign in with Apple
        </button>
    )
}

export default AppleSignInButton
