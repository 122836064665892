import React, { useState, useEffect } from 'react';
import Toggle from './Toggle';
import Tooltip from './Tooltip';
import { toast } from "sonner";

const Miscellaneous = ({ data, handleUpdate }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        const propertiesData = [
            // {
            //     checked: false,
            //     title: 'Top Offers On Search Results',
            //     text: 'Top Offers On Search Results',
            //     property: 'top_offers_on_results'
            // },
            {
                checked: data?.keepa_on_results || false,
                title: 'Keepa on Search Results',
                text: 'Keepa on Search Results',
                property: 'keepa_on_results'
            },
            {
                checked: data?.store_geo_location || false,
                title: 'Store Geo Location',
                text: 'Store Geo Location',
                property: 'store_geo_location'
            },
            // {
            //     checked: false,
            //     title: 'Dark mode (beta)',
            //     text: 'Dark mode (beta)',
            //     property: 'dark_mode'
            // }
        ];

        setProperties(propertiesData);
    }, [data]);

    const handleChange = (e, idx) => {
        const updatedProperties = properties.map((property, index) => {
            if (index === idx) {
                return { ...property, checked: e };
            }
            return property;
        });

        setProperties(updatedProperties);
        
        // Prepare the updated data object
        const updatedData = updatedProperties.reduce((acc, property) => {
            acc[property.property] = property.checked;
            return acc;
        }, {});

        handleUpdate(updatedData);
    };

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='flex flex-col w-full border-primary-light bg-primary p-4 mt-4' style={{
            border: '0.5px solid #76F99B',
            borderRadius: '24px',
        }}>
            <div className='flex cursor-pointer items-center mb-2'
                onClick={toggleAccordion}>
                <div className='mr-1 text-primary-light font-bold' style={{
                    fontSize: '16px',
                }}>
                    Miscellaneous
                </div>
                <div>
                    {/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}
                </div>
            </div>
            {isOpen && (
                <>
                    {properties.map((property, idx) => (
                        <div key={idx} className="flex text-white justify-between bg-secondary p-4 rounded-lg align-center mb-2" style={{ fontSize: '13px' }}>
                            <div className='w-3/4 flex items-center' style={{ textAlign: 'left' }}>
                                {property.title} <Tooltip text={property.text} />
                            </div>
                            <div className='w-1/4 flex justify-end items-center'>
                                <Toggle checked={property.checked} handleChange={(e) => handleChange(e, idx)} />
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

export default Miscellaneous;
