import React, { useEffect, useState } from 'react';
import Toggle from '../SettingComponents/Toggle';
import { toast } from 'sonner';
import { updateSpreadsheetId } from '../../redux/actions/productActions';

const GoogleSheetsAPI = ({data, spreadsheetId, setSpreadsheetId}) => {
    const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';
    const SCOPES = 'https://www.googleapis.com/auth/spreadsheets';

    const [gapiInited, setGapiInited] = useState(false);
    const [gisInited, setGisInited] = useState(false);
    const [tokenClient, setTokenClient] = useState(null);
    // const [spreadsheetId, setSpreadsheetId] = useState(localStorage.getItem('spreadsheetId'));
    const [token, setToken] = useState(localStorage.getItem('token') || null);

    const [initialized, setInitialized] = useState(false);
    const sheetTitle = 'Sheet1';

    useEffect(() => {
        const loadGapiScript = () => {
            const script = document.createElement('script');
            script.src = 'https://apis.google.com/js/api.js';
            script.onload = gapiLoaded;
            document.body.appendChild(script);
        };

        const loadGisScript = () => {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.onload = gisLoaded;
            document.body.appendChild(script);
        };

        loadGapiScript();
        loadGisScript();
    }, []);

    useEffect(() => {
        // console.log('Token in useEffect:', token);

        if (token && window.gapi.client) {
            // console.log('gapi.client:', window.gapi.client);
            setInitialized(true);
            window.gapi.client.setToken({ access_token: token });
            document.getElementById('signout_button').style.visibility = 'visible';
            // document.getElementById('authorize_button').innerText = 'Refresh';
        }
    }, [token, gisInited, gapiInited, tokenClient]);

    const gapiLoaded = () => {
        window.gapi.load('client', initializeGapiClient);
    };

    const initializeGapiClient = async () => {
        await window.gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: [DISCOVERY_DOC],
        });

        // console.log('GAPI client initialized', token, window.gapi.client);
        if (token && window.gapi.client) {
            window.gapi.client.setToken({ access_token: token });
            document.getElementById('signout_button').style.visibility = 'visible';
            // document.getElementById('authorize_button').innerText = 'Refresh';
        }

        setGapiInited(true);
        maybeEnableButtons();
    };

    const gisLoaded = () => {
        const client = window.google.accounts.oauth2.initTokenClient({
            client_id: CLIENT_ID,
            scope: SCOPES,
            callback: '',
        });
        setTokenClient(client);
        setGisInited(true);
        maybeEnableButtons();
    };

    const maybeEnableButtons = () => {
        if (gapiInited && gisInited) {
            document.getElementById('authorize_button').style.visibility = 'visible';
        }
    };

    const handleAuthClick = () => {
        if (!tokenClient) {
            console.error('Token client is not initialized');
            return;
        }

        tokenClient.callback = async (resp) => {
            if (resp.error !== undefined) {
                console.error('Error during authorization:', resp);
                return;
            }

            // console.log('Access Token:', resp);
            setToken(resp.access_token);
            localStorage.setItem('token', resp.access_token);
            document.getElementById('signout_button').style.visibility = 'visible';
            // document.getElementById('authorize_button').innerText = 'Refresh';
            await initializeSpreadsheet();
        };

        if (window.gapi.client.getToken() === null) {
            tokenClient.requestAccessToken({ prompt: 'consent' });
        } else {
            tokenClient.requestAccessToken({ prompt: '' });
        }
    };

    const handleSignoutClick = () => {
        const token = window.gapi.client.getToken();
        if (token !== null) {
            window.google.accounts.oauth2.revoke(token.access_token);
            window.gapi.client.setToken('');
            localStorage.removeItem('token');
            localStorage.removeItem('spreadsheetId');
            setToken(null);
            // setSpreadsheetId(null);
            document.getElementById('content').innerText = '';
            // document.getElementById('authorize_button').innerText = 'Authorize Google Sheets';
            document.getElementById('signout_button').style.visibility = 'hidden';
        }
    };

    const initializeSpreadsheet = async () => {
        if (!spreadsheetId) {
            // Create a new spreadsheet

            console.log('Creating new spreadsheet...');
            const response = await window.gapi.client.sheets.spreadsheets.create({
                properties: {
                    title: 'Scan Profit',
                },
            });
            setSpreadsheetId(response.result.spreadsheetId);

            const resultFromAPI = await updateSpreadsheetId(response.result.spreadsheetId);

            // console.log('Result from API:', resultFromAPI);
            const headersArray = Object.keys(data);
            await createHeader(response.result.spreadsheetId, headersArray);
            localStorage.setItem('spreadsheetId', response.result.spreadsheetId);
            // console.log('New Spreadsheet ID:', response.result.spreadsheetId);
            // await writeData([['Name', 'Email']]); // Add headers
        }
    };

    const sheetExists = async (spreadsheetId, sheetTitle) => {
        try {
            const response = await window.gapi.client.sheets.spreadsheets.get({
                spreadsheetId: spreadsheetId,
            });
            const sheets = response.result.sheets;
            return sheets.some(sheet => sheet.properties.title === sheetTitle);
        } catch (err) {
            document.getElementById('content').innerText = err.message;
            return false;
        }
    };

    const createSheet = async (spreadsheetId, sheetTitle) => {
        try {
            await window.gapi.client.sheets.spreadsheets.batchUpdate({
                spreadsheetId: spreadsheetId,
                resource: {
                    requests: [
                        {
                            addSheet: {
                                properties: {
                                    title: sheetTitle,
                                },
                            },
                        },
                    ],
                },
            });
            document.getElementById('content').innerText = `Sheet "${sheetTitle}" created successfully.`;
        } catch (err) {
            document.getElementById('content').innerText = err.message;
            console.error(err);
        }
    };

    const createHeader = async (spreadsheet, headers) => {
        if (!spreadsheet) {
            // document.getElementById('content').innerText = 'Spreadsheet ID is not set.';
            return;
        }
    
        const range = `${sheetTitle}!A1:${String.fromCharCode(65 + headers.length - 1)}1`; // Example: A1:C1 for 3 headers
    
        try {
            const body = {
                values: [headers]
            };
    
            const response = await window.gapi.client.sheets.spreadsheets.values.update({
                spreadsheetId: spreadsheet,
                range: range,
                valueInputOption: 'RAW',
                resource: body
            });
    
            // console.log('Header Update Response:', response.result);
            // toast.success("Header updated successfully");
            // document.getElementById('content').innerText = 'Header updated successfully.';
        } catch (err) {
            console.error('Error:', err);
            document.getElementById('content').innerText = err.message;
        }
    };
    

    const writeData = async (values) => {
        if (!spreadsheetId) {
            document.getElementById('content').innerText = 'Spreadsheet ID is not set.';
            return;
        }

        // Fetch existing data to find the last row
        const range = `${sheetTitle}!A:A`; // Change the range as per your needs
        let lastRow = 0;

        try {
            const response = await window.gapi.client.sheets.spreadsheets.values.get({
                spreadsheetId: spreadsheetId,
                range: range,
            });

            if (response.result.values) {
                lastRow = response.result.values.length;
            }

            const body = {
                values: values
            };

            // Append data to the next row
            const appendRange = `${sheetTitle}!A${lastRow + 1}:B${lastRow + 1}`;
            const appendResponse = await window.gapi.client.sheets.spreadsheets.values.append({
                spreadsheetId: spreadsheetId,
                range: appendRange,
                valueInputOption: 'RAW',
                insertDataOption: 'INSERT_ROWS',
                resource: body
            });

            // console.log('Append Response:', appendResponse.result);
            toast.success("Data esported successfully")
            // document.getElementById('content').innerText = 'Data appended successfully.';
        } catch (err) {
            console.error('Error:', err);
            document.getElementById('content').innerText = err.message;
        }
    };

    const writeDataHandler = async () => {
        // console.log('Data:', data);
        const valuesArray = Object.values(data);
        // console.log('Values Array:', valuesArray);
        await writeData([valuesArray]);
    };

    const handleSheetToggle = (e, idx) => {
        if (e) {
            handleAuthClick()
        } else {
            handleSignoutClick()
        }
    };
    // console.log('Spreadsheet ID:', spreadsheetId);
    return (

        <div className='flex flex-col w-full border-primary-light bg-primary p-4 mt-4'>
            <button className='bg-secondary text-primary-light py-2 px-4 rounded-md mt-2 hover:bg-primary-light hover:text-secondary transition duration-300'
                id="authorize_button" onClick={handleAuthClick} style={{ display: token ? 'none' : 'block' }}>
                    Authorize Google Sheets
                    </button>
                    {
                token && (
                    <button className='bg-secondary text-primary-light py-2 px-4 mb-2 rounded-md mt-2 hover:bg-primary-light hover:text-secondary transition duration-300'
                        onClick={writeDataHandler}>Export Data</button>
                )}
            <button className='bg-secondary text-primary-light py-2 px-4 rounded-md mt-2 hover:bg-primary-light hover:text-secondary transition duration-300'
                id="signout_button" onClick={handleSignoutClick} style={{ display: token ? 'block' : 'none' }}>
                        Turn Off Google Sheets
                </button>
            {/* <div key={1} className="flex text-white justify-between bg-secondary p-4 rounded-lg align-center mb-2" style={{ fontSize: '13px' }}>
                <div className='w-3/4 flex' style={{ textAlign: 'left' }}>

                    <div className='ml-2'> Access Sheets </div>
                </div>
                <div className='w-1/4 flex justify-end items-center'>
                    <Toggle handleChange={handleSheetToggle} checked={token} />
                </div>
            </div> */}

            <pre id="content" style={{ whiteSpace: 'pre-wrap' }}></pre>
        </div>
    );

};

export default GoogleSheetsAPI;
