import React, { useState, useEffect } from "react";
import Tooltip from "./Tooltip";
import Select from "../Select";

const UserDetails = ({
  data,
  handleUpdate,
  settings,
  handleUpdateMarketplace,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [inputValue, setInputValue] = useState("Sushil Kumar");
  const [userData, setUserData] = useState([]);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    setUserData(data);
    setEmail(data.email || "");
  }, [data]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleEmailUpdate = () => {
    const updatedData = { ...userData, email };
    setUserData(updatedData);
    console.log(updatedData, "updatedData");
    handleUpdate(updatedData);
    setIsEditingEmail(false);
  };

  return (
    <div
      className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
      style={{
        border: "0.5px solid #76F99B",
        borderRadius: "24px",
      }}
    >
      <div
        className="flex cursor-pointer items-center mb-2"
        onClick={toggleAccordion}
      >
        <div
          className="mr-1 text-primary-light font-bold"
          style={{
            fontSize: "16px",
          }}
        >
          User Details
        </div>
        <div>{/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}</div>
      </div>
      {isOpen && (
        <>
          <div className="bg-secondary p-2 rounded-lg mb-3">
            <div className="flex items-center text-white mb-1 font-bold text-sm">
              Username
              <Tooltip text="Username is your unique identifier. It cannot be changed." />
            </div>
            <div className="w-full">
              <input
                type="text"
                value={userData?.name}
                onChange={handleInputChange}
                placeholder="Your Name"
                disabled
                className="text-white border border-primary-light rounded-xl bg-primary py-2 w-full"
                style={{
                  paddingLeft: "10px",
                  borderRadius: "12px",
                }}
              />
            </div>
          </div>
          <div className="bg-secondary p-2 rounded-lg mb-3">
            <div className="flex text-white mb-1 font-bold text-sm">
              Email
              <Tooltip text="Email is your unique identifier. It cannot be changed." />
            </div>
            <div className="flex items-center capitalize">
              {isEditingEmail ? (
                <>
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Your Email"
                    disabled
                    className="text-white border border-primary-light rounded-xl bg-primary py-2 w-full"
                    style={{
                      paddingLeft: "10px",
                      borderRadius: "12px",
                    }}
                  />
                  {/* <button
                                        className='bg-primary-light text-primary font-bold rounded-xl px-4 py-1 ml-2'
                                        onClick={handleEmailUpdate}
                                    >
                                        Update
                                    </button> */}
                </>
              ) : (
                <>
                  <input
                    type="email"
                    value={email}
                    readOnly
                    disabled
                    placeholder="Your Email"
                    className="text-white border border-primary-light rounded-xl bg-primary py-2 w-full"
                    style={{
                      paddingLeft: "10px",
                      borderRadius: "12px",
                    }}
                  />
                  {/* <button
                                        className='bg-primary-light text-primary font-bold rounded-xl px-4 py-1 ml-2'
                                        onClick={() => setIsEditingEmail(true)}
                                    >
                                        Change
                                    </button> */}
                </>
              )}
            </div>
          </div>
          <div className="bg-secondary p-2 rounded-lg mb-3">
            <div className="flex text-white mb-1 font-bold text-sm">
              Subscription <Tooltip text="This shows your current plan." />
            </div>
            <div className="flex items-center">
              <input
                type="text"
                value={userData?.plan || "--"}
                placeholder="Your Subscription plan"
                className={` text-white border border-primary-light rounded-xl bg-primary py-2 w-full`}
                style={{
                  paddingLeft: "10px",
                  borderRadius: "12px",
                }}
              />
              <a
                className="bg-primary-light text-primary font-bold rounded-xl px-4 py-1 ml-2"
                href="https://app.scanprofit.com/"
                target="_blank"
              >
                Modify/Cancel
              </a>
            </div>
          </div>
          {/* <div className="bg-secondary p-2 rounded-lg mb-3">
            <div className="flex text-white mb-1 font-bold text-sm">
              Home Marketplace{" "}
              <Tooltip text="Amazon marketplace where you want to buy and sell products." />
            </div>
            <div className="flex items-center">
              <Select
                inputValue={settings?.marketplace}
                handleDropdownChange={(e) => handleUpdateMarketplace(e)}
                dropdownOptions={[
                  "amazon.com",
                  "amazon.de",
                  "amazon.fr",
                  "amazon.ca",
                  "amazon.it",
                ]}
              />
            </div>
          </div> */}
          {/* <div className="bg-secondary p-2 rounded-lg mb-3">
                        <div className='flex text-white mb-1 font-bold text-sm'>
                            Subscription <Tooltip text="Click to Reset your Subscription details." />
                        </div>
                        <div className='flex items-center'>
                            <input type="text" value={"Sushil Kumar"}
                                placeholder="Your Name" className={` text-white border border-primary-light rounded-xl bg-primary py-2 w-full`}
                                style={{
                                    paddingLeft: '10px',
                                    borderRadius: '12px',
                                }} />
                            <button className='bg-primary-light text-primary font-bold rounded-xl px-4 py-1 ml-2'>
                                Reset
                            </button>
                        </div>
                    </div> */}
          {/* <div className="bg-secondary p-2 rounded-lg mb-1">
                        <div className='flex text-white mb-1 font-bold text-sm'>
                            Merchant Token <Tooltip text="This is your unique identifier. It cannot be changed." />
                        </div>
                        <input type="text" value={""}
                            className={` text-white border border-primary-light rounded-xl bg-primary py-2 w-full`}
                            style={{
                                paddingLeft: '10px',
                                borderRadius: '12px',
                            }} />
                    </div> */}
        </>
      )}
    </div>
  );
};

export default UserDetails;
