import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

const Dimension = ({ data }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const formatLength = (lengthInCm) => {
        if(lengthInCm < 100) {
            return lengthInCm + ' cm';
        }

        if (lengthInCm) {
            return (lengthInCm / 100).toFixed(2) + ' m';
        }

        return '--';
    }

    const formatWeight = (weightInGrams) => {
        const gramsToPounds = 0.00220462; // Conversion factor from grams to pounds
        const gramsToOunces = 0.03527396; // Conversion factor from grams to ounces
    
        if (weightInGrams < 453.592) { // 453.592 grams = 1 pound
            return (weightInGrams * gramsToOunces).toFixed(2) + ' oz';
        }
        if (weightInGrams) {
            return (weightInGrams * gramsToPounds).toFixed(2) + ' lb';
        }
        return '--';
    }
    

    const properties = [
        {
            title: 'Width',
            value: data?.width ? formatLength(data.width) : '--'
        },
        {
            title: 'Height',
            value: data?.height ? formatLength(data.height) : '--'
        },
        {
            title: 'Length',
            value: data?.length ? formatLength(data.length) : '--'
        },
        {
            title: 'Weight',
            value: data?.weight ? formatWeight(data.weight) : '--'
        }

    ];

    return (
        <React.Fragment>
            <div className='flex flex-col w-full border-primary-light bg-primary p-4 mt-4' style={{
                border: '0.5px solid #76F99B',
                borderRadius: '24px',
            }}>
                <div className=''>
                    <div className='flex cursor-pointer items-center mb-2'
                        onClick={toggleAccordion}>
                        <div className='mr-1 text-primary-light font-bold' style={{
                            fontSize: '16px',
                        }}>
                            Dimensions
                        </div>
                        <div>
                            {/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}
                        </div>
                    </div>
                    {isOpen && (
                        <div className="bg-secondary p-4 rounded-xl">
                            {
                                properties.map((property, index) => (
                                    <div key={index} className="flex justify-between" style={{
                                        fontSize: '13px',
                                    }}>
                                        <div>{property.title}</div>
                                        <div className='text-primary-light'>{property.value}</div>
                                    </div>
                                ))}
                        </div>
                    )}

                </div>
            </div>
        </React.Fragment>
    );
};

export default Dimension;
