import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { FaCheck } from "react-icons/fa";
import { IoAlertCircle } from "react-icons/io5";

const Alerts = ({ data }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const properties = [
        // {
        //     heading: 'Amazon Share Buy Box',
        //     subheading: data?.amazonShareBuyBox ? 'This product has Amazon Share Buy Box' : 'This product don\'t have Amazon Share Buy Box',
        //     tick: true
        // },
        // {
        //     heading: 'Size',
        //     subheading: data?.size,
        //     tick: true
        // },
        // {
        //     heading: 'Private Label',
        //     subheading: 'This product has few historic sellers which is a sign it could be PL',
        //     tick: data?.privateLabel ? true : false
        // },
        // {
        //     heading: 'Meltable',
        //     subheading: data?.meltable ? 'This product is meltable' : 'This product is not meltable',
        //     cross: data?.meltable? false : true,
        //     tick: data?.meltable ? true : false,
        // },
        {
            heading: 'Low Price Fee',
            subheading: data?.low_price_FBA ? 'This product has low price FBA' : 'This product does not have low price FBA',
            tick: data?.low_price_FBA ? true : false
        },
        // {
        //     heading: 'Variations',
        //     subheading: data?.variations ? 'This product has variations' : 'This product does not have variations',
        //     tick: data?.variations ? true : false
        // }
    ];

    return (
        <div className='flex flex-col w-full border-primary-light bg-primary p-4 mt-4' style={{
            border: '0.5px solid #76F99B',
            borderRadius: '24px',
        }}>
            <div className=''>
                <div className='flex cursor-pointer items-center mb-2'
                    onClick={toggleAccordion}>
                    <div className='mr-1 text-primary-light font-bold' style={{
                        fontSize: '16px',
                    }}>
                        Alerts
                    </div>
                    <div>
                        {/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}
                    </div>
                </div>
                {isOpen && (
                    <div class="">
                        <div className="flex justify-between bg-secondary p-4 rounded-lg mt-2" style={{
                            fontSize: '13px',
                        }}>
                            <div className='' style={{ textAlign: 'left' }}>
                                <div style={{ fontSize: '16px' }}>{"Meltable"}</div>
                                <h4 className={`${data?.meltable ? 'text-[#F0AD4E]' : 'text-primary-light'}`} style={{ fontSize: '13px' }}>{data?.meltable ? "The product is Meltable" : "The product is not Meltable"}</h4>
                            </div>
                            <div>
                                {
                                    data?.meltable ?
                                        (
                                            <div className='bg-secondary rounded-full'>
                                                <IoAlertCircle className='text-[#F0AD4E] w-6 h-6' />
                                            </div>
                                        ) : (
                                            <div className='bg-primary-light rounded-full p-1'>
                                                <FaCheck className='text-secondary' />
                                            </div>
                                        )
                                }
                            </div>

                        </div>
                        <div className="flex justify-between bg-secondary p-4 rounded-lg mt-2" style={{
                            fontSize: '13px',
                        }}>
                            <div className='' style={{ textAlign: 'left' }}>
                                <div style={{ fontSize: '16px' }}>{"Amazon Share Buy Box"}</div>
                                <h4 className={`${data?.amazonShareBuyBox ? 'text-[#F0AD4E]' : 'text-primary-light'}`} style={{ fontSize: '13px' }}>{data?.amazonShareBuyBox ? "This product has Amazon Share Buy Box" : "This product don\'t have Amazon Share Buy Box"}</h4>
                            </div>
                            <div>
                                {
                                    data?.amazonShareBuyBox ?
                                        (
                                            <div className='bg-secondary rounded-full'>
                                                <IoAlertCircle className='text-[#F0AD4E] w-6 h-6' />
                                            </div>
                                        ) : (
                                            <div className='bg-primary-light rounded-full p-1'>
                                                <FaCheck className='text-secondary' />
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                        {properties.map((property, index) =>
                            <div key={index} className="flex justify-between bg-secondary p-4 rounded-lg mt-2" style={{
                                fontSize: '13px',
                            }}>
                                <div className='' style={{ textAlign: 'left' }}>
                                    <div style={{ fontSize: '16px' }}>{property.heading}</div>
                                    <h4 className={`${property.tick ? 'text-primary-light' : 'text-[#F0AD4E]'}`} style={{ fontSize: '13px' }}>{property.subheading}</h4>
                                </div>
                                <div>
                                    {
                                        property.tick ? (
                                            <div className='bg-primary-light rounded-full p-1'>
                                                <FaCheck className='text-secondary' />
                                            </div>
                                        ) : (
                                            <div className='bg-secondary rounded-full'>
                                                <IoAlertCircle className='text-[#F0AD4E] w-6 h-6' />
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        )}
                        <div className="flex justify-between bg-secondary p-4 rounded-lg mt-2" style={{
                            fontSize: '13px',
                        }}>
                            <div className='' style={{ textAlign: 'left' }}>
                                <div style={{ fontSize: '16px' }}>{"Variations"}</div>
                                <h4 className={`${data?.variations ? 'text-[#F0AD4E]' : 'text-primary-light'}`} style={{ fontSize: '13px' }}>{data?.variations ? "The product has some variations" : "The product do not variations"}</h4>
                            </div>
                            <div>
                                {
                                    data?.variations ?
                                        (
                                            <div className='bg-secondary rounded-full'>
                                                <IoAlertCircle className='text-[#F0AD4E] w-6 h-6' />
                                            </div>
                                        ) : (
                                            <div className='bg-primary-light rounded-full p-1'>
                                                <FaCheck className='text-secondary' />
                                            </div>
                                        )
                                }
                            </div>

                        </div>
                        <div className="flex justify-between bg-secondary p-4 rounded-lg mt-2" style={{
                            fontSize: '13px',
                        }}>
                            <div className='' style={{ textAlign: 'left' }}>
                                <div style={{ fontSize: '16px' }}>{"Private Label"}</div>
                            </div>
                            <div>
                                <h4 className={`${data?.privateLabel ? 'text-[#F0AD4E]' : 'text-primary-light'}`} style={{ fontSize: '13px' }}>{data?.privateLabel ? "Likely" : "Not Likely"}</h4>
                            </div>
                        </div>
                        <div className="flex justify-between bg-secondary p-4 rounded-lg mt-2" style={{
                            fontSize: '13px',
                        }}>
                            <div className='' style={{ textAlign: 'left' }}>
                                <div style={{ fontSize: '16px' }}>{"Size"}</div>
                            </div>
                            <div>
                                <h4
                                    className={`${data?.size?.includes('oversize') ? 'text-[#F0AD4E]' : 'text-primary-light'}`}
                                    style={{ fontSize: '13px' }}>{data?.size}</h4>
                            </div>

                        </div>
                    </div>)}
            </div>
        </div>
    );
};

export default Alerts;
