import React, { useState } from 'react';
import { FaRightFromBracket } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const Logout = ({ handleUpdate }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        // remove accessToken from local storage
        const removetoken = async () => {
            await localStorage.removeItem('accessToken');
            console.log("Logout success");
            navigate('/');
            setShowConfirmation(false);
        };

        removetoken();
    };

    const logoutHandler = () => {
        console.log("Logout button clicked");
        setShowConfirmation(true);
    };

    console.log("Logout component rendered", showConfirmation);
    return (
        <React.Fragment>
            {showConfirmation && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="absolute inset-0 bg-black opacity-50" onClick={() => setShowConfirmation(false)}></div>
                    <div className="bg-secondary border border-primary-light rounded-lg p-6 z-10 max-w-sm w-[90%] mx-auto">
                    <p className="font-Inter text-md   font-semibold text-white">
                    Confirm Logout
        </p>
        <p className="font-Inter mt-2 text-sm text-white">Are you sure you want to logout?</p>
                        <h3 className="text-white text-lg mb-4"></h3>
                        <p className="text-white mb-6"></p>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="bg-secondary text-primary-light cursor-pointer px-4 py-1 rounded border border-primary-light hover:bg-primary-light hover:text-secondary transition duration-300"
                                style={{
                                }}
                                onClick={() => setShowConfirmation(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-primary-light text-primary cursor-pointer px-4 py-1 rounded hover:text-secondary transition duration-300"
                                onClick={handleLogout}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className='flex flex-col w-full border-primary-light bg-primary p-4 mt-4' style={{
                border: '0.5px solid #76F99B',
                borderRadius: '24px',
            }}>
                {isOpen && (
                    <div
                        className="flex text-lg text-white justify-between bg-[#6C3737] p-4 cursor-pointer rounded-lg align-center mb-2 hover:bg-[#663535] hover:text-white"
                        style={{ border: '0.5px solid #E74B4B' }}
                        onClick={logoutHandler}
                    >
                        <div className='w-3/4 flex' style={{ textAlign: 'left' }}>
                            <div>Logout</div>
                        </div>
                        <div className='w-1/4 flex justify-end items-center'>
                            <FaRightFromBracket />
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default Logout;
