import React, { useState } from 'react';
import { searchProducts } from '../../redux/actions/productActions';
import SearchProductCard from './SearchProductCard';
import { AiOutlineSearch, AiOutlineClose } from 'react-icons/ai';
import { FaSearch } from "react-icons/fa";

const AsinComponent = ({ setAsin, settingsData }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visibleCount, setVisibleCount] = useState(5);
    const [showNoResults, setShowNoResults] = useState(false);

    const handleSearch = (e) => {
        e.preventDefault();
        const fetchProducts = async () => {
            if(searchTerm === '') return;
            setData([]);
            setShowNoResults(false);
            setLoading(true);
            const data = await searchProducts(searchTerm);
            setData(data);
            if(data?.length === 0) {
                setShowNoResults(true);
            }
            setVisibleCount(5); // Reset the visible count on new search
            setLoading(false);
        };
        fetchProducts();
    };

    const handleLoadMore = () => {
        setVisibleCount((prevCount) => prevCount + 5);
    };

    return (
        <div>
            <form onSubmit={handleSearch} className="max-w-md mx-auto flex">
                <input
                    type="text"
                    placeholder="Search your Product"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="flex-grow px-4 py-2 mr-2 text-primary rounded-l-md border bg-[#DBEEDD] focus:outline-none focus:border-blue-500"
                    style={{
                        border: '2px solid #7FA19080'
                    }}
                />
                <button
                    type="submit"
                    className="bg-secondary rounded-md text-primary-light py-2 px-3 rounded-r-md hover:bg-primary-light hover:text-secondary transition duration-300"
                >
                    <FaSearch />
                </button>
            </form>

            {loading && (
                <div className='mt-4'>
                    <div className='text-center text-primary-light mt-6'>
                        <div className="loading-spinner"></div>
                        <p>Loading...</p>
                    </div>
                </div>
            )}

            {data?.slice(0, visibleCount).map((product, index) => (
                <SearchProductCard key={index} data={product} loading={loading} setAsin={setAsin} settingsData={settingsData} />
            ))}

            {visibleCount < data?.length && (
                <button
                    onClick={handleLoadMore}
                    className="w-full bg-secondary text-primary-light py-2 px-4 rounded-md mt-2 hover:bg-primary-light hover:text-secondary transition duration-300"
                >
                    Load More
                </button>
            )}
            {
                showNoResults && (
                    <div className='mt-4 text-center'>
                        <p className='text-primary-light'>No Products Found</p>
                    </div>
                )
            }
            <div className='flex justify-center' style={{ height: '60px' }}></div>
        </div>
    );
};

export default AsinComponent;
