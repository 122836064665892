import React from 'react'
import Switch from 'react-switch';

const Toggle = ({checked, handleChange, idx = -1}) => {
    return (
        <Switch
            onChange={(e) => handleChange(e, idx)}
            checked={checked}
            offColor="#133224"
            offHandleColor='#376c46'
            onColor="#133224"
            onHandleColor="#77f99b"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={16}
            width={40}
        />
    )
}

export default Toggle
