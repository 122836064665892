import React, { useState, useEffect, useRef } from 'react';
import Toggle from './Toggle';
import Tooltip from './Tooltip';
import Select from '../Select';
import SaveButtons from '../SaveButtons';

const DefaultValues = ({ data, handleUpdate }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [properties, setProperties] = useState([]);
    const [showSave, setShowSave] = useState(false);
    const inputRefs = useRef([]);

    const dropdownOptions = ["FBA", "FBM"];

    const handleChange = (e, idx) => {
        let value;

        console.log(e, "settings value");
        const updatedProperties = [...properties];
        if (updatedProperties[idx].isToggle) {
            value = e?.target?.value;
        } else {
            value = e?.target?.value?.replace(/[^0-9.]/g, '');
        }

        updatedProperties[idx].value = value;
        setProperties(updatedProperties);
        setShowSave(true);
    };

    const handleChangeSelect = (e, idx) => {
        let value;

        console.log(e, "settings value");
        const updatedProperties = [...properties];
        if (updatedProperties[idx].isToggle) {
            value = e;
        } else {
            value = e?.replace(/[^0-9.]/g, '');
        }

        updatedProperties[idx].value = value;
        setProperties(updatedProperties);
        setShowSave(true);
    };

    const handleSave = () => {
        let updatedData = properties.reduce((acc, property) => {
            acc[property.property] = property.value;
            return acc;
        }, {});

        // update the data object
        data = { ...data, ...updatedData };

        handleUpdate(data);
        setShowSave(false);
    };

    const handleCancel = () => {
        setProperties(createPropertiesFromData(data));
        setShowSave(false);
    };

    useEffect(() => {
        setProperties(createPropertiesFromData(data));
    }, [data]);

    const createPropertiesFromData = (data) => {
        return [
            {
                title: 'Ranks and Prices Time Frame',
                value: data?.ranksprices_timeframe || '0',
                property: 'ranksprices_timeframe',
                text: 'Ranks and Prices Time Frame is the time frame you want to set for the product.',
                isToggle: true,
                type: 'days'
            },
            {
                title: 'Buy Box Analysis Time Frame (days)',
                value: data?.bb_analysis_timeframe || '0',
                property: 'bb_analysis_timeframe',
                text: 'Buy Box Analysis Time Frame is the time frame you want to set for the product.',
                isToggle: true,
                type: 'days'
            },
            {
                title: 'FBM Cost($)',
                value: data?.fbm_cost || '0.00',
                property: 'fbm_cost',
                text: 'FBM Cost is the cost you want to set for the product.'
            },
            {
                title: 'Custom ROI(%)',
                value: data?.custom_roi || '0.00',
                property: 'custom_roi',
                text: 'Custom ROI is the ROI you want to set for the product.'
            },
            {
                title: 'Storage Time (months)',
                value: data?.storage_time || '0',
                property: 'storage_time',
                text: 'Storage Time is the time you want to set for the product.'
            },
            {
                title: 'Local Fulfillment',
                value: data?.local_fullfillment,
                property: 'local_fullfillment',
                text: 'Local Fulfillment is the type of fulfillment you want to set for the product.',
                isToggle: true,
                type: 'fulfillment'
            }
        ];
    };

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const handleFocus = (idx) => {
        const input = inputRefs.current[idx];
        const valueLength = properties[idx].value.length;
        input.setSelectionRange(valueLength, valueLength);
    };

    console.log(properties, "data in default values");
    return (
        <div className='flex flex-col w-full border-primary-light bg-primary p-4 mt-4' style={{
            border: '0.5px solid #76F99B',
            borderRadius: '24px',
        }}>
            <div className='flex cursor-pointer items-center mb-2'
                onClick={toggleAccordion}>
                <div className='mr-1 text-primary-light font-bold' style={{
                    fontSize: '16px',
                }}>
                    Default Values
                </div>
                <div>
                    {/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}
                </div>
            </div>
            {isOpen && (
                <>
                    {properties.map((property, index) => (
                        <div key={index} className={`${property?.isToggle ? '' : 'flex'} text-white justify-between bg-secondary p-4 rounded-lg align-center mb-2`}
                            style={{
                                fontSize: '13px',
                            }}>
                            <div className={`${property?.isToggle ? 'w-full' : 'w-3/4'} flex items-center`}
                                style={{ textAlign: 'left' }}>
                                <span style={{
                                    // maxWidth: "68%"
                                }}>
                                    {property.title}
                                </span>
                                <Tooltip text={property.text} />
                            </div>
                            <div className=''>
                                {(property.isToggle && property.type === 'days') ? (
                                    <Select inputValue={property?.value} handleDropdownChange={(e) => handleChangeSelect(e, index)} dropdownOptions={["Current", "30 Days", "90 Days", "180 Days", "All"]} />
                                ) : (property.isToggle && property.type === 'fulfillment')
                                    ? (
                                        <Select inputValue={property?.value} handleDropdownChange={(e) => handleChangeSelect(e, index)} dropdownOptions={dropdownOptions} />
                                    ) : (
                                        <input
                                            type="text"
                                            value={`${property.title.includes('$') ? '$' : ''}${property?.value}`}
                                            onChange={(e) => handleChange(e, index)}
                                            onFocus={() => handleFocus(index)}
                                            ref={el => inputRefs.current[index] = el}
                                            placeholder="$20"
                                            className={`text-center text-white border border-primary-light rounded-xl bg-primary`}
                                            style={{
                                                borderRadius: '12px',
                                            }}
                                        />
                                    )}
                            </div>
                        </div>
                    ))}
                    {showSave && (
                        <SaveButtons handleSave={handleSave} handleCancel={handleCancel} />
                    )}
                </>
            )}
        </div>
    )
}

export default DefaultValues;