import React, { useState } from 'react'

const Login = ({ loginHandler, loginLoading }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  return (
    <div>
      <div className="text-center">
        <input type="text" placeholder="Enter your email" value={email} className="w-full rounded-md border border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2" onChange={(e) => setEmail(e.target.value)} style={{
          padding: '8px 15px',
        }} />
        <input type="password" placeholder="Enter your password" value={password} className="w-full rounded-md border border-gray-300 focus:outline-none focus:border-blue-500 mt-2" onChange={(e) => setPassword(e.target.value)} style={{
          padding: '8px 15px',
        }} />
        {
          (loginLoading === 'email') ?
            // show loading spinner inside the button
            <button className="w-full bg-secondary text-primary-light py-2 px-4 rounded-md mt-2  hover:text-secondary transition duration-300 mb-10">
              {/* <div className='flex text-center text-primary-light'> */}
              <div className="loading-spinner"></div>
              {/* <p>Loading...</p> */}
              {/* </div> */}
            </button>
            :
            (loginLoading === false) ?
              <button className="w-full bg-secondary text-primary-light py-2 px-4 rounded-md mt-2 hover:bg-primary-light hover:text-secondary transition duration-300 mb-10"
                onClick={() => {
                  loginHandler(email, password);
                }}>
                Login
              </button>
              :
              <button className="w-full bg-secondary text-primary-light py-2 px-4 rounded-md mt-2 hover:bg-primary-light hover:text-secondary transition duration-300 mb-10"
                disabled>
                Login
              </button>
        }
      </div>
    </div>
  )
}

export default Login
