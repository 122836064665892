/*global chrome*/
import React, { useState, useEffect } from 'react';
import './App.css';
import Main from './Main';
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { getUserInfo } from './redux/actions/auth';
import Settings from './Settings';
import { Toaster } from "sonner";
import { fetchSettings } from './redux/actions/productActions';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const hasAccessToken = localStorage.getItem("accessToken");
  // const [settingsData, setSettingsData] = useState(null);
  const [urlType, setUrlType] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    console.log("accessToken >>>> ", accessToken);
    if (accessToken) {
      getUserInfo()
        .then((data) => {
          setUser(data);
          console.log("data in useEffect >>>> ", data);
        })
        .catch((error) => {
          console.log("error in useEffect >>>> ", error);
        })
        .finally(() => {
          setLoading(false);
        });

      // const getSettings = async () => {
      //   const settingsData = await fetchSettings();
      //   setSettingsData(settingsData);
      // }

      // getSettings();
    } else {
      setLoading(false);
    }
  }, []);

  const router = createBrowserRouter([
    {
      path: ":asin?/:marketplace?",
      element: <Main userData={user} />,
    },
    {
      path: "/settings",
      element: hasAccessToken ? <Settings /> : <Navigate to="/" />,
    },
    {
      path: "/",
      element: <Main userData={user} />,
    }
  ]);

  if (loading) {
    return (
      <div className='text-center text-primary-light mt-6'>
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }
  
  return (
    <React.Fragment>
      <Toaster position="top-right" richColors closeButton />
      <RouterProvider router={router} />
    </React.Fragment>
  );
}

export default App;
