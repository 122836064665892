const initState = {
    profit: 0,
    roi: 0,
    total_fees: 0,
    prices: {
        cost_price: 0,
        sale_price: 0,
    },
    profit_percentage: 0,
    roi_percentage: 0,
    selectedFulfillment: 'FBA',
    fullfillmentFees: {
        FBA: 0,
        FBM: 0,
    },
    settings: {
    }
}

export default (state = initState, action) => {
    switch (action.type) {
        case "SET_PROFIT":
            return {
                ...state,
                profit: action.payload,
            };
        case "SET_ROI":
            return {
                ...state,
                roi: action.payload,
            };
        case "SET_TOTAL_FEES":
            return {
                ...state,
                total_fees: action.payload,
            };
        case "SET_PRICES":
            return {
                ...state,
                prices: action.payload,
            };
        case "SET_PROFIT_PERCENTAGE":
            return {
                ...state,
                profit_percentage: action.payload,
            };
        case "SET_ROI_PERCENTAGE":
            return {
                ...state,
                roi_percentage: action.payload,
            };
        case "SET_SELECTED_FULFILLMENT":
            return {
                ...state,
                selectedFulfillment: action.payload,
            };
        case "SET_FULFILLMENT_FEES":
            return {
                ...state,
                fullfillmentFees: action.payload,
            };
        case "UPDATE_GLOBAL_STATES":
            return {
                ...state,
                ...action.payload,
            };
        case "SET_SETTINGS":
            return {
                ...state,
                settings: action.payload,
            };
        default:
            return state;
    }
};