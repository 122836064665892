export const formatNumber = (number) => {
    const num = Number(number);
    // if (num >= 1000000) {
    //     return (num / 1000000).toFixed(1) + 'm';
    // } else if (num >= 1000) {
    //     return (num / 1000).toFixed(1) + 'k';
    // } else {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // }
};


const epochToLocalTime = (epochTime) => {
    const localDate = new Date(epochTime * 1000);
    return localDate.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short'
    });
};

export const convertEpochTimeToLocalTime = (epochTime) => {
    return epochToLocalTime(epochTime);
};


export const formatLength = (lengthInCm) => {
    if(lengthInCm < 100) {
        return lengthInCm + ' cm';
    }

    if (lengthInCm) {
        return (lengthInCm / 100).toFixed(2) + ' m';
    }

    return '--';
}

export const cmToInches = (cm) => {
    return (cm / 2.54).toFixed(2) + ' in';
}

export const formatWeight = (weightInGrams) => {
    const gramsToPounds = 0.00220462; // Conversion factor from grams to pounds
    const gramsToOunces = 0.03527396; // Conversion factor from grams to ounces

    if (weightInGrams < 453.592) { // 453.592 grams = 1 pound
        return (weightInGrams * gramsToOunces).toFixed(2) + ' oz';
    }
    if (weightInGrams) {
        return (weightInGrams * gramsToPounds).toFixed(2) + ' lb';
    }
    return '--';
}