import React, { useState } from 'react';
import { MdOutlineQuestionMark } from 'react-icons/md';

const Tooltip = ({ text }) => {
    const [visible, setVisible] = useState(false);

    const showTooltip = () => setVisible(true);
    const hideTooltip = () => setVisible(false);

    return (
        <div
            className="relative flex items-center cursor-pointer"
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
        >
            <div
                className="bg-primary rounded-full ml-2"
                style={{
                    padding: '2px',
                }}
            >
                <MdOutlineQuestionMark
                    className="text-primary-light"
                    style={{
                        fontSize: '10px',
                    }}
                />
            </div>
            {visible && (
                <div
                    className="absolute mb-2 top-0 left-1/2 transform translate-x-4 px-2 py-1 bg-primary text-white text-xs z-10"
                    style={{
                        width: '150px',
                        border: '0.1px solid #76F99B',
                        borderTopRightRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        textAlign: 'left',
                        fontSize: '11px',
                        boxShadow: '4px 4px 32px 0px #000000CC',
                    }}
                >
                    {text}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
