import React, { useState, useEffect } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { calculateProfitMargin } from '../../utils/ProfitCalculator';

const ROI = ({ data, salesData }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [roi, setRoi] = useState(Number(data?.roi.substring(0, data?.roi.length - 1)));
    const [breakevenSalePrice] = useState(Number(data?.breakeven_sale_price));
    const [salesPrice, setSalesPrice] = useState(Number(data?.prices?.sale_price));
    const [costPrice, setCostPrice] = useState(Number(data?.prices?.cost_price));
    const [amazonFees, setAmazonFees] = useState(Number(data?.total_fees?.total));
    const [profit, setProfit] = useState(calculateProfitMargin(salesPrice, costPrice, amazonFees)[0]);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const resetValues = () => {
        setRoi(Number(data?.roi.substring(0, data?.roi.length - 1)));
        setSalesPrice(Number(data?.prices?.sale_price));
        setProfit(calculateProfitMargin(Number(data?.prices?.sale_price), costPrice, amazonFees)[0]);
    };

    useEffect(() => {
        const roi = Number(data?.roi.substring(0, data?.roi.length - 1));
        const costPrice = Number(data?.prices?.cost_price);
        const amazonFees = Number(data?.total_fees?.total);
        const salesPrice = Number(data?.prices?.sale_price);

        setRoi(roi);
        setCostPrice(costPrice);
        setAmazonFees(amazonFees);
        setSalesPrice(salesPrice);
        setProfit(calculateProfitMargin(salesPrice, costPrice, amazonFees)[0]);
    }, [data]);

    useEffect(() => {
        // Update the profit when salesPrice changes
        setProfit(calculateProfitMargin(salesPrice, costPrice, amazonFees)[0]);
    }, [salesPrice, costPrice, amazonFees]);

    const roiChangeHandler = (e) => {
        const roiValue = (e.target.value);
        setRoi(roiValue);
        const calcProfit = (roiValue / 100) * costPrice;
        const calcSalesPrice = Number((costPrice + calcProfit + amazonFees).toFixed(2));
        setSalesPrice(calcSalesPrice);
    };

    const profitChangeHandler = (e) => {
        let profitValue = e.target.value;
        if(isNaN(profitValue)) {
            profitValue = 0;
        }
        setProfit(profitValue);
        profitValue = Number(profitValue);
        const calcRoi = Number(((profitValue / costPrice) * 100).toFixed(2));
        setRoi(calcRoi);
        const calcSalesPrice = Number((costPrice + profitValue + amazonFees).toFixed(2));
        setSalesPrice(calcSalesPrice);
    };

    const profitCalculator = (roi) => {
        // calculate profit for a fixed roi and sale price
        return (roi / 100) * costPrice;
    };

    const RoiPercents = [0, 10, 20, 25, 30, 40, 50, 75, 80, 100, 200];
    return (
        <div className='flex flex-col w-full border-primary-light bg-primary p-4 mt-4' style={{
            border: '0.5px solid #76F99B',
            borderRadius: '24px',
        }}>
            <div className='flex justify-between mb-4'>
                <div className='flex cursor-pointer items-center'
                    onClick={toggleAccordion}>
                    <div className='mr-1 text-primary-light font-bold' style={{
                        fontSize: '16px',
                    }}>
                        R.O.I
                    </div>
                    <div>
                        {/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}
                    </div>
                </div>
            </div>
            {isOpen && (
                <>
                    <div className="bg-secondary p-4 rounded-lg">
                        <div className='flex justify-between text-primary' style={{
                            fontSize: '13px',
                        }}>
                            <div style={{
                                maxWidth: '30%',
                            }}>
                                <div>
                                    <input
                                        type="text"
                                        value={roi}
                                        onChange={roiChangeHandler}
                                        onBlur={resetValues}
                                        className='text-center text-white border border-primary-light rounded-xl bg-primary-dark p-1 mb-2 w-1/2'
                                    />
                                </div>
                            </div>
                            <div style={{
                                maxWidth: '30%',
                            }}>
                                <div>
                                    <input
                                        type="text"
                                        value={isNaN(profit) ? 0 : profit}
                                        onChange={profitChangeHandler}
                                        onBlur={resetValues}
                                        className='text-center text-white border border-primary-light rounded-xl bg-primary-dark p-1 mb-2 w-1/2'
                                    />
                                </div>
                            </div>
                            <div style={{
                                maxWidth: '30%',
                            }}>
                                <div>
                                    <input
                                        type="text"
                                        value={salesPrice}
                                        disabled
                                        className='text-center text-white border border-primary-light rounded-xl bg-primary-dark p-1 mb-2 w-1/2'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between mt-3" style={{
                            fontSize: '13px',
                            fontWeight: 'bold',
                        }}>
                            <div className="w-1/4 text-center">ROI</div>
                            <div className="w-1/4 text-center">Profit</div>
                            <div className="w-1/4 text-center">Sale Price</div>
                        </div>
                        {
    RoiPercents.map((roiRow, index) => (
        <div 
            key={index} 
            className="flex justify-between text-primary-light mt-1" 
            style={{ fontSize: '13px' }}
        >
            <div className="w-1/4 text-center">{roiRow}%</div>
            <div className="w-1/4 text-center">
                {'$' + profitCalculator(roiRow).toFixed(2)}
            </div>
            <div className="w-1/4 text-center">
                {'$' + (costPrice + profitCalculator(roiRow) + amazonFees).toFixed(2)}
            </div>
        </div>
    ))
}

                    </div>
                </>
            )}
        </div>
    );
};

export default ROI;
