import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data, title, valueProperty }) => {
    // Prepare data for the pie chart

    console.log(data, 'PieChart');
    const chartData = {
        labels: data?.map(item => item.sellerId),
        datasets: [
            {
                label: title,
                data: data?.map(item => item[valueProperty]),
                backgroundColor: [
                    '#ee8501', '#ffca39', '#FFCE56', '#4BC0C0', '#FF9F40', '#FF6384', '#36A2EB', '#4BC0C0', '#9966FF',
                ],
                hoverOffset: 4
            }
        ]
    };

    // Customize the tooltip to show additional information
    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const item = data[tooltipItem.dataIndex];
                        return [
                            `Seller: ${item.sellerName}`,
                            `Percentage Won: ${item[valueProperty].toFixed(2)}%`,
                            `Avg Price: $${item.avgPrice.toLocaleString()}`,
                            // `New Offers: ${item.avgNewOfferCount}`,
                            // `Used Offers: ${item.avgUsedOfferCount}`,
                            `FBA: ${item.isFBA ? 'Yes' : 'No'}`,
                            // `Last Seen: ${item.lastSeen}`
                        ];
                    }
                }
            },
            legend: {
                display: false // Disable legend to remove labels at the top
            }
        }
    };

    console.log('PieChart');
    return (
        <div
            className="bg-secondary flex flex-col items-center justify-center rounded-lg shadow-sm w-full p-4"

        >
            <div className="m-auto"
                style={{
                    maxWidth: '70%',
                    // minHeight: '200px'
                }}
            >
                <Pie data={chartData} options={options} />
            </div>
        </div>

    );
};

export default PieChart;
