import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import ProductDetails from "../ProductDetails";
import { LiaAmazon } from "react-icons/lia";
import Rating from "@mui/material/Rating";
import { FaCopy } from "react-icons/fa";
import { IoCubeOutline } from "react-icons/io5";
import { BsFillBoxFill } from "react-icons/bs";
import { HiMiniBars3 } from "react-icons/hi2";
import { LuMonitor } from "react-icons/lu";
import { FaGoogle } from "react-icons/fa";
import Tooltip from "../SettingComponents/Tooltip";
import TooltipSimple from "../SettingComponents/TooltipSimple";
import {
  cmToInches,
  formatLength,
  formatNumber,
  formatWeight,
} from "../../utils/formatData";
import { toast } from "sonner";

const Overview = ({
  asin,
  eanList,
  data,
  basicInfo,
  amazonFees,
  salesData,
  loading,
  rating,
  settingsData,
  amazonLink,
  dimensions,
  description,
  referralFeePercent,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const asinValue = asin ? asin : "-";
  const eanValue = eanList?.length > 0 && eanList[0] ? eanList[0] : "";

  return (
    <React.Fragment>
      <div
        className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
        style={{
          border: "0.5px solid #76F99B",
          borderRadius: "24px",
        }}
      >
        <div className="flex items-center">
          <div
            class="inline-block top-20 left-20 gap-0 rounded-t-xl bg-secondary w-[40%]"
            style={{
              border: "0.5px solid #76F99B",
              background: "rgba(118, 249, 155, 0.35)",
              borderRadius: "30px",
              minHeight: "120px",
            }}
          >
            {!data?.image ? (
              <div
                className="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 dark:bg-secondary w-[40%]"
                style={{
                  border: "0.5px solid #76F99B",
                  background: "rgba(118, 249, 155, 0.35)",
                  borderRadius: "30px",
                  height: "130px",
                }}
              >
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            ) : (
              <img
                src={data?.image}
                alt="Product 1"
                class="w-auto m-auto rounded-t-lg"
                style={{
                  // width: '90%',
                  borderRadius: "30px",
                  minHeight: "120px",
                }}
              />
            )}
          </div>

          <div className="text-primary-light text-left w-[60%]  px-4">
            {!loading ? (
              <>
                <div
                  className="text-white font-bold"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {basicInfo?.brand ? basicInfo?.brand : ""}
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                  className="group relative cursor-default"
                >
                  {basicInfo?.productName
                    ? basicInfo.productName.length > 35
                      ? basicInfo.productName.substring(0, 35) + "..."
                      : basicInfo.productName
                    : "--"}
                  <TooltipSimple
                    text={basicInfo?.productName}
                    width={"extra"}
                  />
                </div>
                <div
                  className="text-white font-bold"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {basicInfo?.category ? basicInfo?.category : ""}
                </div>
                <div className="flex items-end mt-1">
                  <Rating
                    name="read-only"
                    value={Number(rating?.rating)}
                    precision={0.1}
                    readOnly
                    style={{
                      fontSize: "13px",
                      color: "#76F99B",
                    }}
                  />
                  <span
                    className="text-white ml-1"
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    {rating?.ratings_total
                      ? formatNumber(Number(rating.ratings_total))
                      : 0}
                  </span>
                </div>
                <div
                  className="mt-2"
                  style={{
                    fontSize: "0.7rem",
                  }}
                >
                  <div className="text-white">
                    ASIN: {asinValue}
                    <span className="cursor-pointer text-primary-light">
                      <FaCopy
                        className="ml-2"
                        style={{
                          fontSize: "0.7rem",
                          display: "inline",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(asinValue);
                          toast.success("ASIN copied to clipboard");
                        }}
                      />
                    </span>
                  </div>
                  {eanValue && (
                    <div className="text-white text-left">
                      EAN: {eanValue}
                      <span className="cursor-pointer text-primary-light">
                        <FaCopy
                          className="ml-2"
                          style={{
                            fontSize: "0.7rem",
                            display: "inline",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(eanValue);
                            toast.success("EAN copied to clipboard");
                          }}
                        />
                      </span>
                    </div>
                  )}
                </div>
                <div className="flex justify-between w-full mt-2">
                  <div className="flex items-center group relative bg-secondary rounded-md px-2 py-1 text-primary-light hover:bg-primary-light hover:text-secondary transition duration-300">
                    <BsFillBoxFill className="w-3 h-3" />
                    <TooltipSimple
                      width={"wide"}
                      text={`<strong>Product Dimensions :</strong><br/>
                                                        Width: ${formatLength(
                                                          dimensions?.width
                                                        )} (${cmToInches(
                        dimensions?.width
                      )})<br/>
                                                        Height: ${formatLength(
                                                          dimensions?.height
                                                        )} (${cmToInches(
                        dimensions?.height
                      )})<br/>
                                                        Length: ${formatLength(
                                                          dimensions?.length
                                                        )} (${cmToInches(
                        dimensions?.length
                      )})<br/>
                                                        Weight: ${formatWeight(
                                                          dimensions?.weight
                                                        )}`}
                    />
                  </div>
                  <div className="flex items-center group relative bg-secondary rounded-md px-2 py-1 text-primary-light hover:bg-primary-light hover:text-secondary transition duration-300">
                    <HiMiniBars3 className="w-3 h-3" />
                    <TooltipSimple
                      text={description || "No description"}
                      width={"extra"}
                    />
                  </div>
                  <div
                    className="flex items-center group relative cursor-pointer bg-secondary rounded-md px-2 py-1 text-primary-light hover:bg-primary-light hover:text-secondary transition duration-300"
                    onClick={() => {
                      window.open(amazonLink, "_blank");
                    }}
                  >
                    <LiaAmazon className="w-3 h-3" />
                    <TooltipSimple text="View Product on Amazon" />
                  </div>
                  <div
                    className="flex items-center group relative cursor-pointer bg-secondary rounded-md px-2 py-1 text-primary-light hover:bg-primary-light hover:text-secondary transition duration-300"
                    onClick={() => {
                      window.open(
                        `https://www.google.com/search?q=${basicInfo?.productName}`,
                        "_blank"
                      );
                    }}
                  >
                    <FaGoogle className="w-3 h-3" />
                    <TooltipSimple text="Search Product title on Google" />
                  </div>
                  <div className="flex items-center group relative bg-secondary rounded-md px-2 py-1 text-primary-light hover:bg-primary-light hover:text-secondary transition duration-300">
                    <LuMonitor className="w-3 h-3" />
                  </div>
                </div>
              </>
            ) : (
              <div
                role="status"
                className="animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center flex items-center"
              >
                <div className="w-full">
                  <div className="h-2.5 bg-gray-400 rounded-full dark:bg-secondary max-w-[360px] mb-4"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary max-w-[200px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary max-w-[100px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary max-w-[100px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary max-w-[100px]"></div>
                </div>
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </div>
        <div className="mt-4">
          <div className="">
            <div
              className="flex cursor-pointer items-center text-white"
              onClick={toggleAccordion}
            >
              <div
                className="mr-1 text-white"
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Overview
              </div>
              <div>{/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}</div>
            </div>
            {isOpen && (
              <ProductDetails
                loading={loading}
                data={data}
                amazonFees={amazonFees}
                salesData={salesData}
                settingsData={settingsData}
                referralFeePercent={referralFeePercent}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Overview;
