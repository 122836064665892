import React from 'react'

const SaveButtons = ({handleCancel, handleSave}) => {
    return (
        <div className="flex text-white justify-end rounded-lg align-center">
            <button
                className='bg-primary-light text-primary-dark font-bold rounded-lg py-2 px-4 mt-2'
                onClick={handleCancel}
            >
                Cancel
            </button>
            <button
                className='bg-primary-light text-primary-dark font-bold rounded-lg py-2 px-4 ml-4 mt-2'
                onClick={handleSave}
            >
                Save
            </button>
        </div>
    )
}

export default SaveButtons
