import React, { useState, useEffect } from 'react'
import AreaChartWithCustomTooltip from '../AreaChartWithCustomTooltip';
import PieChart from '../PieChart';
import { formatNumber } from '../../utils/formatData';
import TooltipSimple from '../SettingComponents/TooltipSimple';

const chartColors = [
    '#ee8501', '#ffca39', '#FFCE56', '#4BC0C0', '#FF9F40', '#FF6384', '#36A2EB', '#4BC0C0', '#9966FF',
];

const options = ['30 Days', '90 Days', '180 Days', 'All'];

const BuyBoxAnalysis = ({ data, settingsData }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [graphData, setGraphData] = useState(data);
    const [selectedOption, setSelectedOption] = useState(settingsData?.defaultValues?.bb_analysis_timeframe || '30 Days');
    const [selectedData, setSelectedData] = useState(data?.days_30);

    useEffect(() => {
        const option = settingsData?.defaultValues?.bb_analysis_timeframe || '30 Days';
        setSelectedOption(option);
        // const selectedData = (selectedOption === '30 Days') ? data?.days_30 :
        //     (selectedOption === '90 Days') ? data?.days_90 :
        //         (selectedOption === '180 Days') ? data?.days_180 :
        //             data?.days_all;
        // setSelectedData(selectedData);
        // setGraphData(selectedData);
    }, [data]);

    useEffect(() => {
        const selectedData = (selectedOption === '30 Days') ? data?.days_30 :
            (selectedOption === '90 Days') ? data?.days_90 :
                (selectedOption === '180 Days') ? data?.days_180 :
                    data?.days_all;
        setSelectedData(selectedData);
        setGraphData(selectedData);
    }, [selectedOption]);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    console.log(graphData, 'graphData PieChart');
    return (
        <>
            <div className='flex flex-col w-full border-primary-light bg-primary p-4 mt-4' style={{
                border: '0.5px solid #76F99B',
                borderRadius: '24px',
            }}>
                <div className='flex cursor-pointer items-center mb-2'
                    onClick={toggleAccordion}>
                    <div className='mr-1 text-primary-light font-bold' style={{
                        fontSize: '16px',
                    }}>
                        Buy box Analysis
                    </div>
                    <div>
                        {/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}
                    </div>
                </div>
                {isOpen && (
                    <>
                        <div className="">
                            <div className="flex">
                                {options.map((option, index) => (
                                    <div
                                        key={index}
                                        className={`cursor-pointer text-primary-light ${selectedOption === option ? 'text-white' : ''
                                            } bg-secondary px-2 py-1 rounded-lg mr-1 mb-2`}
                                        onClick={() => setSelectedOption(option)}
                                        style={{ fontSize: '12px' }}
                                    >
                                        {option}
                                    </div>
                                ))}
                            </div>
                            <div className='bg-secondary border rounded px-3 mb-2'>
                                {selectedData && selectedData.length > 0 &&
                                    <>
                                        <PieChart
                                            data={selectedData}
                                            // title="Buy Box Analysis"
                                            valueProperty="percentageWon"
                                        />

                                    </>
                                }
                                <div className='flex mt-2'>
                                    <div className="flex flex-wrap mb-2">
                                        {
                                            selectedData && selectedData.length > 0 &&
                                            selectedData?.map((item, index) => (
                                                <div key={index} className="flex items-center w-1/2">
                                                    <div
                                                        className="w-4 h-1 rounded"
                                                        style={{
                                                            backgroundColor: chartColors[index],
                                                        }}
                                                    ></div>
                                                    <span className="ml-1 text-white" style={{ fontSize: '12px' }}>
                                                        {item.sellerName}
                                                    </span>
                                                </div>
                                            ))
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="relative">
                                <table className="w-full mt-2" style={{ fontSize: '13px', borderCollapse: 'collapse' }}>
                                    <thead className="sticky top-0 bg-primary">
                                        <tr className="" style={{ fontWeight: 'bold' }}>
                                            <div className='flex'>
                                                <div className="p-1 flex-2"></div>
                                                <div className="p-1 flex-1">Seller</div>
                                                <div className="p-1 flex-1">Avg Price</div>
                                                <div className="p-1 flex-1">Won</div>
                                                <div className="p-1 flex-1">Last Won</div>
                                            </div>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedData?.map((property, index) => (
                                            <tr key={index}>
                                                <td className="p-0">
                                                    <div className="text-center text-white bg-secondary p-4 rounded-lg"
                                                        style={{
                                                            marginBottom: '2px',
                                                            marginTop: index === 0 ? '0' : '4px',
                                                            padding: '4px 8px',
                                                            fontSize: '12px',
                                                        }}>
                                                        <div className="flex items-center justify-around">
                                                            <div className="p-1 pl-0 pr-2 flex-2">{index + 1}</div> {/* Serial Number */}
                                                            <div className="p-1 flex-1"
                                                                style={{
                                                                    // wrap text if it is too long
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}>{property?.sellerName || '-'}
                                                                </div>
                                                            <div className="p-1 flex-1"
                                                                style={{
                                                                    // show ellipsis if the text is too long
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}>${formatNumber(property?.avgPrice / 100)}</div>
                                                            <div className="p-1 flex-1"
                                                                style={{
                                                                    // show ellipsis if the text is too long
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}>{property?.percentageWon.toFixed(2)}%</div>
                                                            <div className="p-1 flex-1 text-ellipsis">{property?.lastSeen}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>)}
            </div >
        </>
    )
}

export default BuyBoxAnalysis