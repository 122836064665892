import React, { useState, useEffect, useRef } from 'react';
import Toggle from './Toggle';
import Tooltip from './Tooltip';
import { toast } from 'sonner';
import SaveButtons from '../SaveButtons';

const AdditionalCosts = ({ data, handleUpdate }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [properties, setProperties] = useState([]);
    const [showSave, setShowSave] = useState(false);
    const inputRefs = useRef([]);

    const handleChange = (e, idx) => {
        const value = e.target.value.replace(/[^0-9.]/g, ''); // Remove any non-numeric characters
        const updatedProperties = [...properties];
        updatedProperties[idx].value = value;
        setProperties(updatedProperties);
        setShowSave(true);
    };

    const handleToggleChange = (e, idx) => {
        const updatedProperties = [...properties];
        updatedProperties[idx].value = e;
        setProperties(updatedProperties);
        setShowSave(true);
    };

    const handleSave = () => {
        const updatedData = properties.reduce((acc, property) => {
            acc[property.property] = property.value;
            return acc;
        }, {});

        // update the data object
        data = { ...data, ...updatedData };
        handleUpdate(data);
        setShowSave(false);
    };

    const handleCancel = () => {
        setProperties(createPropertiesFromData(data));
        setShowSave(false);
    };

    useEffect(() => {
        setProperties(createPropertiesFromData(data));
    }, [data]);

    const createPropertiesFromData = (data) => {
        return [
            {
                title: 'Prep Fee($)',
                value: data?.prepfee,
                property: 'prepfee',
                text: 'Prep Fee is the fee you want to set for the product.'
            },
            {
                title: 'Misc Fee($)',
                value: data?.miscfee,
                property: 'miscfee',
                text: 'Any other cost associated with the product that you want to be added, like some extra percentage of the product cost.'
            },
            {
                title: 'Misc Fee(%)',
                value: data?.miscfeepercent,
                property: 'miscfeepercent',
                text: 'Misc Fee is the fee you want to set for the product.'
            },
            {
                title: 'Inbound Shipping Fee($)',
                value: data?.inbound_shipping || '--',
                property: 'inbound_shipping',
                text: 'Inbound Shipping Fee is the fee you want to set for the product.'
            },
            {
                title: 'Use Peak Storage Fees',
                value: data?.use_peak_storage_fees || false,
                property: 'use_peak_storage_fees',
                isToggle: true,
                text: 'If it is the fee you want to set for the product.'
            }
        ];
    };

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const handleFocus = (idx) => {
        const input = inputRefs.current[idx];
        const valueLength = properties[idx].value.length;
        input.setSelectionRange(valueLength, valueLength);
    };

    return (
        <div className='flex flex-col w-full border-primary-light bg-primary p-4 mt-4' style={{
            border: '0.5px solid #76F99B',
            borderRadius: '24px',
        }}>
            <div className='flex cursor-pointer items-center mb-2'
                onClick={toggleAccordion}>
                <div className='mr-1 text-primary-light font-bold' style={{
                    fontSize: '16px',
                }}>
                    Additional Costs
                </div>
                <div>
                    {/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}
                </div>
            </div>
            {isOpen && (
                <>
                    {properties.map((property, index) => (
                        <div key={index} className="flex text-white justify-between bg-secondary p-4 rounded-lg align-center mb-2"
                            style={{
                                fontSize: '13px',
                            }}>
                            <div className='w-3/4 flex items-center' style={{ textAlign: 'left' }}>
                                {property.title} <Tooltip text={property.text} />
                            </div>
                            <div className='w-1/4'>
                            {property.isToggle ? (
                                    <Toggle checked={property.value} handleChange={handleToggleChange} idx={index} />
                                ) : (
                                <input
                                    type="text"
                                    value={`${property.value}${property.title.includes('%') ? '%' : property.title.includes('$') ? '$' : ''}`}
                                    onChange={(e) => handleChange(e, index)}
                                    onFocus={() => handleFocus(index)}
                                    ref={el => inputRefs.current[index] = el}
                                    placeholder="$20"
                                    className={`text-center text-white border border-primary-light rounded-xl ${property.title === 'Fullfillment' ? 'bg-secondary' : 'bg-primary-dark'}`}
                                    style={{
                                        borderRadius: '12px',
                                    }}
                                />
                            )}
                            </div>
                        </div>
                    ))}
                    {/* <div className="bg-secondary p-2 rounded-lg mb-3">
                        <div className='flex text-white mb-1 font-bold text-sm'>
                            Inbound Placement (.com only) <Tooltip text="Inbound Placement is the fee you want to set for the product." />
                        </div>
                        <input type="text" value="Sushil Kumar"
                            placeholder="Your Name" className={`text-white border border-primary-light rounded-xl bg-primary py-2 w-full`}
                            style={{
                                paddingLeft: '10px',
                                borderRadius: '12px',
                            }} />
                    </div> */}
                    {/* <div className="flex text-white justify-between bg-secondary p-4 rounded-lg align-center mb-2" style={{ fontSize: '13px' }}>
                        <div className='w-3/4 flex' style={{ textAlign: 'left' }}>
                            Use Peak Storage Fees <Tooltip text="Use Peak Storage Fees is the fee you want to set for the product." />
                        </div>
                        <div className='w-1/4 flex justify-end items-center'>
                            <Toggle checked={data?.use_peak_storage_fees} handleChange={handleChange} />
                        </div>
                    </div> */}
                        {showSave && (
                            <SaveButtons handleSave={handleSave} handleCancel={handleCancel} />
                        )}
                </>
            )}
        </div>
    )
}

export default AdditionalCosts;