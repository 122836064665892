import React, { useState, useEffect, useRef } from "react";
import UserDetails from "./components/SettingComponents/UserDetails";
import VAT from "./components/SettingComponents/VAT";
import BuyingCriteria from "./components/SettingComponents/BuyingCriteria";
import AdditionalCosts from "./components/SettingComponents/AdditionalCosts";
import DefaultValues from "./components/SettingComponents/DefaultValues";
import Miscellaneous from "./components/SettingComponents/Miscellaneous";
import QuickInfo from "./components/SettingComponents/QuickInfo";
import Tags from "./components/SettingComponents/Tags";
import SasPanels from "./components/SettingComponents/SasPanels";
import { fetchSettings, updateSettings } from "./redux/actions/productActions";
import { fetchUserDetails } from "./redux/actions/userActions";

import { RiMoneyDollarBoxFill } from "react-icons/ri";
import { MdChromeReaderMode } from "react-icons/md";
import { IoIosPricetags } from "react-icons/io";
import { GoAlertFill } from "react-icons/go";
import { FaChartLine } from "react-icons/fa";
import { TbResize } from "react-icons/tb";
import { BiSolidOffer } from "react-icons/bi";
import { TbReportMoney } from "react-icons/tb";

import { toast } from "sonner";
import TooltipSimple from "./components/SettingComponents/TooltipSimple";
import Logout from "./components/SettingComponents/Logout";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const [settingsData, setSettingsData] = useState([]);
  const [userData, setUserData] = useState([]);

  const navigate = useNavigate();

  const userDetailsRef = useRef(null);
  const vatRef = useRef(null);
  const buyingCriteriaRef = useRef(null);
  const additionalCostsRef = useRef(null);
  const defaultValuesRef = useRef(null);
  const miscellaneousRef = useRef(null);
  const quickInfoRef = useRef(null);
  const tagsRef = useRef(null);
  const sasPanelsRef = useRef(null);
  const logoutRef = useRef(null);

  const handleIconClick = (ref) => {
    console.log("ref", ref);
    if (ref && ref.current) {
      const element = ref.current;
      const offsetTop = element.getBoundingClientRect().top;
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      const targetScrollPosition = scrollPosition + offsetTop - 100;
      window.scrollTo({ top: targetScrollPosition, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchSettings();
      setSettingsData(data);

      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        console.log("No access token found");
        navigate("/");
      } else {
        const { user } = await fetchUserDetails();
        if (!user) {
          navigate("/");
        }
        setUserData(user);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (settingsData.length === 0) return;
    const updateData = async () => {
      const data = await updateSettings(settingsData);
      console.log("Updated data:", data);
    };
    // updateData();
  }, [settingsData]);

  const handleUpdateUserDetails = (data) => {
    const updatedSettingsData = {
      ...settingsData,
      user: data,
    };

    const updateData = async () => {
      const data = await updateSettings(updatedSettingsData);
      console.log("Updated data:", data);
      setSettingsData(updatedSettingsData);
    };
    updateData();
  };

  const handleUpdateVAT = (data) => {
    const updatedSettingsData = {
      ...settingsData,
      vat: data,
    };

    const updateData = async () => {
      const data = await updateSettings(updatedSettingsData);
      console.log("Updated data:", data);
      setSettingsData(updatedSettingsData);
    };
    updateData();
  };

  const handleUpdateBuyingCriteria = (data) => {
    const updatedSettingsData = {
      ...settingsData,
      buyingCriteria: data,
    };

    console.log("Updated Settings in buying criteria:", updatedSettingsData);
    const updateData = async () => {
      const data = await updateSettings(updatedSettingsData);
      setSettingsData(updatedSettingsData);
      toast.success("Buying criteria updated successfully");
    };
    updateData();
  };

  const handleUpdateQuickInfo = (data) => {
    console.log("QUICK INFO UPDATE DATA:", data);
    const updatedSettingsData = {
      ...settingsData,
      quickInfo: data,
    };

    console.log("Updated Settings in quick info:", updatedSettingsData);
    const updateData = async () => {
      const data = await updateSettings(updatedSettingsData);
      console.log("Updated data:", data);
      setSettingsData(updatedSettingsData);
    };

    toast.success("Quick Info Updated");
    updateData();
  };

  const handleUpdateMiscellaneous = (data) => {
    const updatedSettingsData = {
      ...settingsData,
      miscellaneous: data,
    };

    console.log("Updated Settings in miscellaneous:", updatedSettingsData);
    const updateData = async () => {
      const data = await updateSettings(updatedSettingsData);
      setSettingsData(updatedSettingsData);
    };

    toast.success("Miscellaneous Settings Updated!!");
    updateData();
  };

  const handleUpdateSas = (data) => {
    const updatedSettingsData = {
      ...settingsData,
      panels: data,
    };

    console.log("Updated Settings in sas panels:", updatedSettingsData);
    const updateData = async () => {
      const data = await updateSettings(updatedSettingsData);
      setSettingsData(updatedSettingsData);
    };

    toast.success("Panel Settings Updated!!");
    updateData();
  };

  const handleUpdateMarketplace = (data) => {
    const updatedSettingsData = {
      ...settingsData,
      marketplace: data,
    };
    const updateData = async () => {
      const data = await updateSettings(updatedSettingsData);
      console.log("Updated data:", data);
      setSettingsData(updatedSettingsData);
    };

    toast.success("Marketplace Updated");
    updateData();
  };

  const handleUpdateAdditionalCosts = (data) => {
    const updatedSettingsData = {
      ...settingsData,
      additionalCosts: data,
    };

    console.log("Updated Settings in additional costs", updatedSettingsData);
    const updateData = async () => {
      const data = await updateSettings(updatedSettingsData);
      console.log("Updated data:", data);
      setSettingsData(updatedSettingsData);
    };

    toast.success("Additonal Costs Updated");
    updateData();
  };

  const handleUpdateDefaultValues = (data) => {
    const updatedSettingsData = {
      ...settingsData,
      defaultValues: data,
    };

    console.log("Updated Settings in default values", updatedSettingsData);
    const updateData = async () => {
      const data = await updateSettings(updatedSettingsData);
      setSettingsData(updatedSettingsData);
    };

    toast.success("Default Values Updated");
    updateData();
  };

  console.log("Settings Data:", settingsData);
  return (
    <div className="App">
      <div
        className="flex flex-col w-full border-primary-light bg-primary mt-3 p-3"
        style={{
          position: "sticky",
          top: "2px",
          border: "0.5px solid #76F99B",
          borderRadius: "24px",
          zIndex: "1",
        }}
      >
        <div className="flex">
          <div
            class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark"
            onClick={() => handleIconClick(userDetailsRef)}
            style={
              {
                // background: "#052308",
              }
            }
          >
            <MdChromeReaderMode />
            <TooltipSimple width="auto" text="User Details" />
          </div>
          <div
            class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark"
            onClick={() => handleIconClick(vatRef)}
            style={
              {
                // background: "#052308",
              }
            }
          >
            <IoIosPricetags />
            <TooltipSimple width="auto" text="VAT" />
          </div>
          <div
            class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark"
            onClick={() => handleIconClick(buyingCriteriaRef)}
            style={
              {
                // background: "#052308",
              }
            }
          >
            <GoAlertFill />
            <TooltipSimple width="auto" text="Buying Criteria" />
          </div>
          <div
            class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark"
            onClick={() => handleIconClick(additionalCostsRef)}
            style={
              {
                // background: "#052308",
              }
            }
          >
            <RiMoneyDollarBoxFill className="" />
            <TooltipSimple width="auto" text="Additional Costs" />
          </div>
          <div
            class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark"
            onClick={() => handleIconClick(miscellaneousRef)}
            style={
              {
                // background: "#052308",
              }
            }
          >
            <FaChartLine />
            <TooltipSimple width="auto" text="Miscellaneous" />
          </div>
          <div
            class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark"
            onClick={() => handleIconClick(quickInfoRef)}
            style={
              {
                // background: "#052308",
              }
            }
          >
            <TbResize />
            <TooltipSimple width="auto" text="Quick Info" />
          </div>
          <div
            class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark"
            onClick={() => handleIconClick(tagsRef)}
            style={
              {
                // background: "#052308",
              }
            }
          >
            <BiSolidOffer />
            <TooltipSimple width="auto" text="Tags" />
          </div>
          <div
            class="w-8 h-8 group relative rounded-lg ml-2 bg-primary-light flex justify-center items-center text-primary-dark"
            onClick={() => handleIconClick(sasPanelsRef)}
            style={
              {
                // background: "#052308",
              }
            }
          >
            <TbReportMoney />
            <TooltipSimple width="auto" text="SAS Panels" />
          </div>
        </div>
      </div>

      <div className="text-white">
        <div ref={userDetailsRef}>
          <UserDetails
            userId={settingsData?.userId}
            data={userData}
            handleUpdate={handleUpdateUserDetails}
            settings={settingsData}
            handleUpdateMarketplace={handleUpdateMarketplace}
          />
        </div>
        <div ref={vatRef}>{/* <VAT /> */}</div>
        <div ref={buyingCriteriaRef}>
          <BuyingCriteria
            data={settingsData?.buyingCriteria}
            handleUpdate={handleUpdateBuyingCriteria}
          />
        </div>
        <div ref={additionalCostsRef}>
          <AdditionalCosts
            data={settingsData?.additionalCosts}
            handleUpdate={handleUpdateAdditionalCosts}
          />
        </div>
        <div ref={defaultValuesRef}>
          <DefaultValues
            data={settingsData?.defaultValues}
            handleUpdate={handleUpdateDefaultValues}
          />
        </div>
        <div ref={miscellaneousRef}>
          <Miscellaneous
            data={settingsData?.miscellaneous}
            handleUpdate={handleUpdateMiscellaneous}
          />
        </div>
        <div ref={quickInfoRef}>
          <QuickInfo
            data={settingsData?.quickInfo}
            handleUpdate={handleUpdateQuickInfo}
          />
        </div>
        <div ref={tagsRef}>
          <Tags />
        </div>
        <div ref={sasPanelsRef}>
          <SasPanels
            data={settingsData?.panels}
            handleUpdate={handleUpdateSas}
          />
        </div>
        <div ref={logoutRef}>
          <Logout />
        </div>
        <div
          className="flex justify-center"
          style={{
            height: "60px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Settings;
