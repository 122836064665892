import React from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(...registerables);

const keepaTimeToEpoch = (keepaTime) => {
    return keepaTime + 719528;
};

const epochToUTC = (epochTime) => {
    const utcDate = new Date(epochTime * 1000);
    return utcDate.toUTCString();
};

const convertKeepaTimeToUTC = (keepaTime) => {
    const epochTime = keepaTimeToEpoch(keepaTime);
    return epochToUTC(epochTime);
};

const AreaChartWithCustomTooltip2 = (props) => {
    let { data: chartDataProps } = props;

    // Extract data
    let [dataMain, dataFBM, dataAmazon, dataBsr, dataBb] = chartDataProps || [];

    const valueProperty = props.valueProperty || 'value';

    // Extract labels
    const labels = dataMain?.map(d => d.time) || [];
    const labelsFBM = dataFBM?.map(d => d.time) || [];
    const labelsAmazon = dataAmazon?.map(d => d.time) || [];
    const labelsBsr = dataBsr?.map(d => d.time) || [];
    const labelsBb = dataBb?.map(d => d.time) || [];

    const mergedLabels = () => {
        let labelsArray = [...labels, ...labelsFBM, ...labelsAmazon, ...labelsBb];
        labelsArray = labelsArray.map(label => convertKeepaTimeToUTC(label));

        labelsArray.sort((a, b) => new Date(a) - new Date(b));

        return labelsArray.map(label => new Date(label).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short'
        }));
    };

    const toInt = (value) => parseInt(value) || undefined;

    const data = dataMain?.map(d => toInt(d[valueProperty])).filter(Number.isInteger) || [];
    dataFBM = dataFBM?.map(d => toInt(d[valueProperty])).filter(Number.isInteger) || [];
    dataAmazon = dataAmazon?.map(d => toInt(d[valueProperty])).filter(Number.isInteger) || [];
    dataBsr = dataBsr?.map(d => toInt(d[valueProperty])).filter(Number.isInteger) || [];
    dataBb = dataBb?.map(d => toInt(d[valueProperty])).filter(Number.isInteger) || [];

    const min = Math.min(...data, ...dataFBM, ...dataAmazon, ...dataBb);
    const max = Math.max(...data, ...dataFBM, ...dataAmazon, ...dataBb);

    const valueFormatter = (num) => {
        num = num / 100;
        if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1) + 'B';
        if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + 'M';
        if (num >= 1_000) return (num / 1_000).toFixed(1) + 'k';
        return num.toString();
    };

    const chartData = {
        labels: mergedLabels(),
        datasets: [
            {
                label: 'FBA',
                data,
                borderColor: '#ff5722',
                borderWidth: 1, // Thin lines
                stepped: true, // Stepped lines
                pointRadius: 0,
            },
            {
                label: 'FBM',
                data: dataFBM,
                borderColor: '#3c82f7',
                borderWidth: 1, // Thin lines
                stepped: true, // Stepped lines
                pointRadius: 0,
            },
            {
                label: 'Amazon',
                data: dataAmazon,
                borderColor: '#f90',
                backgroundColor: 'rgba(255, 153, 0, 0.2)', // Background color
                borderWidth: 1, // Thin lines
                stepped: true, // Stepped lines
                pointRadius: 0,
                fill: true, // Fill the area below the line
            },
            {
                label: 'Buy Box',
                data: dataBb,
                borderColor: '#ff00b4',
                borderWidth: 1, // Thin lines
                stepped: true, // Stepped lines
                pointRadius: 0,
            },
        ]
    };

    const chartOptions = {
        plugins: {
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: (context) => {
                        const label = context.dataset.label || '';
                        const value = context.raw || 0;
                        return `${label}: ${valueFormatter(value)}`;
                    }
                }
            },
            legend: {
                display: false
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#333', // Dark text for readability
                },
                grid: {
                    color: '#e5e5e5', // Light grid lines
                },
            },
            y: {
                suggestedMin: min - 50,
                suggestedMax: max + 50,
                ticks: {
                    callback: valueFormatter,
                    color: '#333', // Dark text for readability
                },
                grid: {
                    color: '#e5e5e5', // Light grid lines
                },
            }
        },
        responsive: true,
        maintainAspectRatio: false,
    };

    return (
        <div className="bg-white flex flex-col border border-gray-300 rounded-lg shadow-sm h-[200px] w-full">
            <Line data={chartData} options={chartOptions} />
        </div>
    );
};

export default AreaChartWithCustomTooltip2;
