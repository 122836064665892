import React, { useState, useEffect } from "react";
import SkeletonLine from "./SkeletonLine";
import {
  calculateProfitMargin,
  calculateROI,
  calculateMaximumCost,
  calculateBreakevenSalePrice,
} from "../utils/ProfitCalculator";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TooltipSimple from "./SettingComponents/TooltipSimple";
import { formatNumber } from "../utils/formatData";

const ProductDetails = ({
  data,
  loading,
  amazonFees,
  salesData,
  settingsData,
  referralFeePercent,
}) => {
  const dispatch = useDispatch();
  const totalFees = useSelector((state) => state.globalStates.total_fees);
  const prices = useSelector((state) => state.globalStates.prices);
  const settings = useSelector((state) => state.globalStates.settings);

  const [profitDollars, setProfitDollars] = useState("");
  const [profitPercent, setProfitPercent] = useState("");
  const [profit, setProfit] = useState(data?.profit || "");
  const [roi, setROI] = useState(data?.roi || "");
  const [maxCost, setMaxCost] = useState(data?.max_cost || "");
  const [breakeven, setBreakeven] = useState(data?.brekeven || "");

  useEffect(() => {
    if (data) {
      const salePrice = parseFloat(prices.sale_price) || 0;
      const costPrice = parseFloat(prices.cost_price) || 0;
      const [profitDollars, profitPercent] = calculateProfitMargin(
        salePrice,
        costPrice,
        totalFees
      );

      dispatch({
        type: "SET_PRICES",
        payload: {
          sale_price: data.prices?.sale_price || "",
          cost_price: data.prices?.cost_price || "",
        },
      });

      setProfitDollars(data.profit_dollars || "");
      setProfitPercent(data.profit_percent || "");
      setProfit(data.profit || "");
      setROI(data.roi || "");
      setMaxCost(data.max_cost || "");
    }
  }, [data]);

  const handlePriceChange = (e, type) => {
    let value = e.target.value.replace(/[^0-9.]/g, "");

    if (type === "cost_price" || type === "sale_price") {
      if (value.length > 2) {
        const dollars = value.slice(0, -2);
        const cents = value.slice(-2);
        value = `${dollars}.${cents}`;
      }
    }

    dispatch({
      type: "SET_PRICES",
      payload: {
        ...prices,
        [type]: value,
      },
    });
  };

  useEffect(() => {
    const salePrice = parseFloat(prices.sale_price) || 0;
    const costPrice = parseFloat(prices.cost_price) || 0;

    const [profitDollars, profitPercent] = calculateProfitMargin(
      salePrice,
      costPrice,
      totalFees
    );

    const roiValue = calculateROI(salePrice, costPrice, totalFees);

    // setmaxcost only ifsale price changes
    if (prices.sale_price !== data?.prices?.sale_price) {
      const max_cost = calculateMaximumCost(
        salePrice,
        costPrice,
        totalFees,
        Number(settings?.buyingCriteria?.minProfit) || 0,
        profitDollars,
        settings
      );
      setMaxCost(max_cost);
    } else {
      setMaxCost(data?.max_cost);
    }
    // set breakeven only if cost price changes
    if (prices.cost_price !== data?.prices?.cost_price) {
      const breakevenSalePrice = calculateBreakevenSalePrice(
        costPrice,
        salePrice,
        totalFees,
        referralFeePercent
      );
      setBreakeven(breakevenSalePrice);
    } else {
      setBreakeven(data?.brekeven);
    }
    // setMaxCost(`${max_cost}`);

    setProfitDollars(
      profitDollars && profitDollars !== "NaN" ? profitDollars : "--"
    );
    setProfitPercent(
      profitPercent && profitPercent !== "NaN" ? profitPercent : "--"
    );
    setProfit(`$${profitDollars}`);
    setROI(`${roiValue}`);
  }, [prices, totalFees]);

  const bsr = data?.BSR;
  const estimatedPrice = salesData?.sales_estimation?.monthly_sales;

  console.log(Number(settingsData?.buyingCriteria?.minbsr), "minbsr");
  return (
    <div className="product-details-container rounded-lg text-white">
      <div className="flex justify-between mt-3" style={{ fontSize: "14px" }}>
        {/* <div className="flex mt-2"> */}
        <div
          className={`rounded-lg text-center relative mr-2 min-w-[31%] 
                ${
                  Number(settingsData?.buyingCriteria?.minbsr) === 0
                    ? "text-primary-light bg-secondary"
                    : Number(settingsData?.buyingCriteria?.minbsr) >
                        Number(
                          data?.BSR?.percentage?.substring(
                            0,
                            data?.BSR?.percentage.length - 1
                          )
                        ) || bsr?.BSR === -1
                    ? "text-white bg-[#E74B4B]"
                    : "text-primary bg-primary-light"
                }`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-3 pt-1">BSR</div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              {bsr?.BSR !== -1 ? (
                <>
                  <span className="mr-1 overflow-hidden text-ellipsis whitespace-nowrap">
                    {bsr?.BSR ? formatNumber(Number(bsr?.BSR)) : "--"}
                  </span>
                  <span>
                    {data?.BSR?.percentage
                      ? "(" + data?.BSR?.percentage + ")"
                      : "(--)"}
                  </span>
                </>
              ) : (
                "--"
              )}
              <TooltipSimple
                text={
                  Number(settingsData?.buyingCriteria?.minbsr) >
                  Number(data?.BSR?.BSR)
                    ? `BSR(${data?.BSR?.BSR}) is less than desired BSR(${settingsData?.buyingCriteria?.minbsr})`
                    : `BSR(${data?.BSR?.BSR}) is above desired BSR level (${settingsData?.buyingCriteria?.minbsr})`
                }
              />
            </div>
          )}
        </div>

        <div
          className={`rounded-lg text-center relative mr-2 min-w-[31%] text-primary-light bg-secondary`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-3 pt-1">Est. Sales</div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              <span className="block overflow-hidden text-ellipsis whitespace-nowrap">
                {estimatedPrice ? formatNumber(estimatedPrice) : "--"}
              </span>
              <TooltipSimple
                text={
                  estimatedPrice
                    ? `We estimate this product sells ${formatNumber(
                        estimatedPrice
                      )} units per month`
                    : "Est. Sales: --"
                }
              />
            </div>
          )}
        </div>

        <div
          className={`rounded-lg text-center relative min-w-[31%] text-primary-light bg-secondary`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-3 pt-1">Max. Cost</div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              <span className="block overflow-hidden text-ellipsis whitespace-nowrap">
                {/* show -- if NaN as well */}
                {maxCost && maxCost !== "NaN" ? "$" + maxCost : "--"}
              </span>
              <TooltipSimple
                text={`Maximum Cost: ${
                  maxCost && maxCost !== "NaN" ? "$" + maxCost : "--"
                }`}
              />
            </div>
          )}
        </div>
        {/* </div> */}
      </div>

      <div className="flex justify-between mt-3" style={{ fontSize: "14px" }}>
        <div className="box flex-1 rounded-lg mr-4 bg-secondary">
          <div className="flex items-center">
            <div className="w-[40%] text-center px-1">Cost Price</div>
            <div className="text-primary-light flex-1">
              {loading ? (
                <SkeletonLine />
              ) : (
                <input
                  type="text"
                  value={`$${prices.cost_price}`}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[^0-9]/g, "");
                    e.target.value = `$${rawValue}`;
                    handlePriceChange(e, "cost_price");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ".") {
                      e.preventDefault();
                    }
                  }}
                  className={`text-center text-white border border-primary-light rounded-xl bg-primary-dark`}
                  style={{ borderRadius: "12px" }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="box flex-1 rounded-lg mr-4 bg-secondary">
          <div className="flex items-center">
            <div className="w-[40%] text-center px-1">Sales Price</div>
            <div className="text-primary-light flex-1">
              {loading ? (
                <SkeletonLine />
              ) : (
                <input
                  type="text"
                  value={`$${prices.sale_price}`}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[^0-9]/g, "");
                    e.target.value = `$${rawValue}`;
                    handlePriceChange(e, "sale_price");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ".") {
                      e.preventDefault();
                    }
                  }}
                  className={`text-center text-white border border-primary-light rounded-xl bg-primary-dark`}
                  style={{ borderRadius: "12px" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex justify-between mt-3 gap-x-2"
        style={{ fontSize: "14px" }}
      >
        <div
          className={`rounded-lg text-center relative mr-2 ${
            settingsData?.quickInfo?.show_profit === true ? "block" : "hidden"
          } flex-1 ${
            profitDollars < Number(settingsData?.buyingCriteria?.minProfit)
              ? "text-white bg-[#E74B4B]"
              : "text-primary bg-primary-light"
          }`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-3 pt-1">Profit</div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              <span className="mr-1 text-ellipsis whitespace">
                {profit ? profit : "--"}
              </span>
              {/* <TooltipSimple
                                    text={
                                        (Number(settingsData?.buyingCriteria?.minbsr) > Number(data?.BSR?.BSR)) ? `BSR(${data?.BSR?.BSR}) is less than desired BSR(${settingsData?.buyingCriteria?.minbsr})` : `BSR(${data?.BSR?.BSR}) is above desired BSR level (${settingsData?.buyingCriteria?.minbsr})`
                                    }
                                /> */}
            </div>
          )}
        </div>
        <div
          className={`rounded-lg text-center relative mr-2 ${
            settingsData?.quickInfo?.show_profit_margin === true
              ? "block"
              : "hidden"
          } flex-1 ${
            profitDollars < Number(settingsData?.buyingCriteria?.minProfit)
              ? "text-white bg-[#E74B4B]"
              : "text-primary bg-primary-light"
          }`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-1 pt-1 text-sm">
              Profit Margin
            </div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              <span className="mr-1 text-ellipsis whitespace">
                {profitPercent ? profitPercent : "--"}
              </span>
              {/* <TooltipSimple
                                    text={
                                        (Number(settingsData?.buyingCriteria?.minbsr) > Number(data?.BSR?.BSR)) ? `BSR(${data?.BSR?.BSR}) is less than desired BSR(${settingsData?.buyingCriteria?.minbsr})` : `BSR(${data?.BSR?.BSR}) is above desired BSR level (${settingsData?.buyingCriteria?.minbsr})`
                                    }
                                /> */}
            </div>
          )}
        </div>

        <div
          className={`rounded-lg text-center relative mr-2 ${
            settingsData?.quickInfo?.show_roi === true ? "block" : "hidden"
          } flex-1 ${
            roi < Number(settingsData?.buyingCriteria?.minroi)
              ? "text-white bg-[#E74B4B]"
              : "text-primary bg-primary-light"
          }`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-3 pt-1">ROI</div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              <span className="mr-1 overflow text-ellipsis whitespace">
                {roi && roi !== "NaN" ? roi + "%" : "--"}
              </span>
              {/* <TooltipSimple
                                    text={
                                        (Number(settingsData?.buyingCriteria?.minbsr) > Number(data?.BSR?.BSR)) ? `BSR(${data?.BSR?.BSR}) is less than desired BSR(${settingsData?.buyingCriteria?.minbsr})` : `BSR(${data?.BSR?.BSR}) is above desired BSR level (${settingsData?.buyingCriteria?.minbsr})`
                                    }
                                /> */}
            </div>
          )}
        </div>
        <div
          className={`rounded-lg text-center relative ${
            Number(prices?.sale_price) < Number(data?.brekeven)
              ? "text-white bg-[#E74B4B]"
              : "text-primary bg-primary-light"
          } flex-1 ${
            settingsData?.quickInfo?.show_breakeven === true
              ? "block"
              : "hidden"
          }`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-2 pt-1">Break Even</div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              <span className="mr-1 overflow-hidden text-ellipsis whitespace-nowrap">
                {breakeven ? "$" + breakeven : "--"}
              </span>
              {/* <TooltipSimple
                                    text={
                                        (Number(settingsData?.buyingCriteria?.minbsr) > Number(data?.BSR?.BSR)) ? `BSR(${data?.BSR?.BSR}) is less than desired BSR(${settingsData?.buyingCriteria?.minbsr})` : `BSR(${data?.BSR?.BSR}) is above desired BSR level (${settingsData?.buyingCriteria?.minbsr})`
                                    }
                                /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
